import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { PROFILE_TABLE_SWITCH_NETSUITE_CHANGED } from "redux/actions/actionTypes";

import { FormControlLabel, Switch } from "@mui/material";
import CanRenderChildren from "components/shared/functions/CanRenderChildren";

const FailedNetsuiteToggle = () => {
  const dispatch = useDispatch();
  const profileState = useSelector((state: RootState) => state.profile);

  const handleSwitchChange = () => {
    dispatch({
      type: PROFILE_TABLE_SWITCH_NETSUITE_CHANGED,
      switchState: !profileState.switchState,
    });
  };

  return (
    <CanRenderChildren permissionName="canRetryNetSuite">
      <FormControlLabel
        sx={{ display: "block" }}
        control={
          <Switch
            checked={profileState.switchState}
            onChange={handleSwitchChange}
            name="netSuiteFilter"
            color="primary"
          />
        }
        label="Failed NetSuite Uploads"
      />
    </CanRenderChildren>
  );
};

export default FailedNetsuiteToggle;
