import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableContainer,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAllVmses, getFacilitiesByVmses } from "api/VmsApi";
import { TITLE_CHANGED, VMS_UPDATE_PAYPERIOD, VMS_UPDATE_VMSES } from "redux/actions/actionTypes";
import PayPeriodRangeSelector from "components/history/PayPeriodRangeSelector";
import JobAssignmentApi from "api/JobAssignmentApi";
import VmsesSearch from "./VmsesSearch";
import SearchIcon from "@mui/icons-material/Search";
import { useSnackbar } from "notistack";
import { VmsTableFacilityItem, VmsTableItem } from "types/vms/vmsTableItem";
import VmsProfileTableRowVms from "./VmsProfileTableRowVms";
import { VmsNameDisplayName } from "types/vms/vmsNameDisplayName";

const VmsProfile = () => {
  const [vmsProfiles, setVmsProfiles] = useState<VmsTableItem[] | null>(null);
  const [vmses, setVmses] = useState<VmsNameDisplayName[]>([]);
  const [maxDateFilters, setMaxDateFilters] = useState<Date>(new Date());
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const headerState = useSelector((state: RootState) => state.header);
  const currentFilters = useSelector((state: RootState) => state.vmsProfile);
  const [vmsesLoading, setVmsesLoading] = useState(false);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleOnFilterDateChange = (startDate?: string, endDate?: string) => {
    dispatch({
      type: VMS_UPDATE_PAYPERIOD,
      startDate,
      endDate,
    });
  };

  const handleSearch = async () => {
    if (!currentFilters.startDate || !currentFilters.endDate) {
      enqueueSnackbar("Please select a valid date range", { variant: "info" });
      return;
    }

    if (currentFilters.vmses.length === 0) {
      enqueueSnackbar("Please select at least one VMS", { variant: "info" });
      return;
    }

    setVmsProfiles(null);
    setVmsesLoading(true);
    const response = await getFacilitiesByVmses(
      currentFilters.startDate,
      currentFilters.endDate,
      currentFilters.vmses.map((x) => x.name)
    );

    const vmsTable: VmsTableItem[] = [];

    currentFilters.vmses.forEach((vmsName) => {
      const facilities = response.filter((facility) => facility.vmsName === vmsName.name);

      const facilitiesTable: VmsTableFacilityItem[] = facilities.map((facility) => ({
        facilityId: facility.facilityId,
        facilityName: facility.facilityName,
        travelers: facility.travelers,
        vmsName: facility.vmsName,
        isLoading: false,
      }));

      vmsTable.push({ vmsName: vmsName.displayName, facilities: facilitiesTable });
    });

    setVmsProfiles(vmsTable);
    setVmsesLoading(false);
  };

  useEffect(() => {
    if (!currentFilters.startDate || !currentFilters.endDate) {
      dispatch({
        type: VMS_UPDATE_PAYPERIOD,
        startDate: headerState.startDate,
        endDate: headerState.endDate,
      });
    }
  }, [headerState]);

  useEffect(() => {
    dispatch({ type: TITLE_CHANGED, title: "VMS" });

    getAllVmses()
      .then((vmses) => {
        setVmses(vmses);

        const vmsesSelected = vmses.filter((vms) =>
          currentFilters.vmses.map((x) => x.name).includes(vms.name)
        );

        dispatch({
          type: VMS_UPDATE_VMSES,
          vmses: vmsesSelected,
        });
      })
      .catch(() => {
        enqueueSnackbar("Error loading VMSes", { variant: "error" });
      });

    JobAssignmentApi.getMaxDate()
      .then((response) => {
        setMaxDateFilters(new Date(response));
      })
      .catch(() => {
        enqueueSnackbar("There was a problem setting up period selector", { variant: "error" });
      });
  }, []);

  return (
    <div className="view-container">
      <Box mt={1} mb={2}>
        <Grid container spacing={2}>
          <Grid item>
            {maxDateFilters && (
              <PayPeriodRangeSelector
                title="Period Range"
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                onHandleChange={handleOnFilterDateChange}
                startDate={currentFilters.startDate}
                endDate={currentFilters.endDate}
                maxDate={maxDateFilters}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <VmsesSearch vmsesOptions={vmses} />
          </Grid>

          <Grid item xs={12} md={5} lg={3}>
            <Button
              onClick={handleSearch}
              variant="contained"
              color="primary"
              startIcon={<SearchIcon />}>
              Search
            </Button>
          </Grid>
        </Grid>
      </Box>

      {vmsesLoading && <CircularProgress />}

      {vmsProfiles !== null && (
        <TableContainer>
          <Table>
            <TableBody>
              {vmsProfiles.map((vmsProfile, index) => (
                <VmsProfileTableRowVms vmsProfile={vmsProfile} key={vmsProfile.vmsName + index} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default VmsProfile;
