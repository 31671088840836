import { useAppSelector } from "hooks";
import { Box } from "@mui/system";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { PayrollLockStatusTimecardsWithExpenses } from "types/payPeriod/PayrollLockStatusTimecardsWithExpenses";
import BPPTable, { Column } from "./bppTable/BPPTable";
import { Button } from "@mui/material";
import TimesheetApi from "api/TimesheetApi";
import { TimesheetSubStatus } from "commons/Enums";
import { useSnackbar } from "notistack";
import { RefreshOutlined } from "@mui/icons-material";

type LockPayrollTimecardsWithExpensesDialogContentProps = {
  timecardsWithExpenses: PayrollLockStatusTimecardsWithExpenses[];
  onActionTaken: () => void;
};

type PayrollLockStatusTimecardsWithExpensesIds =
  | "TimesheetId"
  | "RecruiterId"
  | "TravelerName"
  | "Actions";

const LockPayrollTimecardsWithExpensesDialogContent = ({
  timecardsWithExpenses,
  onActionTaken,
}: LockPayrollTimecardsWithExpensesDialogContentProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const headerState = useAppSelector((state) => state.header);

  const formatDate = (date: string) => {
    return moment(new Date(date)).format("MM/DD/YYYY");
  };

  const onDidNotWork = async (timesheetId: string) => {
    try {
      await TimesheetApi.notWorkedAndNoExpenses(timesheetId, TimesheetSubStatus.DidNotWork);
    } catch (error) {
      enqueueSnackbar("There was an error processing the timecard", { variant: "error" });
    }
    onActionTaken();
  };

  const onDidNotSubmit = async (timesheetId: string) => {
    try {
      await TimesheetApi.notWorkedAndNoExpenses(timesheetId, TimesheetSubStatus.DidNotSubmit);
    } catch (error) {
      enqueueSnackbar("There was an error processing the timecard", { variant: "error" });
    }
    onActionTaken();
  };

  const onConvertToExpenseOnly = async (timesheetId: string) => {
    try {
      await TimesheetApi.convertToExpenseTimecard(timesheetId);
    } catch (error) {
      enqueueSnackbar("There was an error processing the timecard", { variant: "error" });
    }
    onActionTaken();
  };

  const columns: Column<
    PayrollLockStatusTimecardsWithExpensesIds,
    PayrollLockStatusTimecardsWithExpenses
  >[] = [
    {
      label: "Traveler",
      id: "TravelerName",
      format(value, rowData) {
        return (
          <Link
            to={{
              pathname: `/home/${rowData.RecruiterId}/${rowData.TimesheetId}/${formatDate(
                headerState.startDate
              )}/${formatDate(headerState.endDate)}`,
            }}
            target="_blank">
            {rowData.TravelerName}
          </Link>
        );
      },
    },
    {
      label: "Actions",
      id: "Actions",
      format(value, rowData) {
        return (
          <Box display="flex">
            <Button onClick={() => onDidNotWork(rowData.TimesheetId)}>Did Not Work</Button>
            <Button onClick={() => onDidNotSubmit(rowData.TimesheetId)}>Did Not Submit</Button>
            <Button onClick={() => onConvertToExpenseOnly(rowData.TimesheetId)}>
              Convert to Expense Only Timecard
            </Button>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <Box my={2}>
        <BPPTable<PayrollLockStatusTimecardsWithExpenses, PayrollLockStatusTimecardsWithExpensesIds>
          data={timecardsWithExpenses}
          columns={columns}
          maxHeight="50vh"
          showFilters={false}
          showClearButton={false}
          customSearch={
            <Box display={"flex"} justifyContent={"end"} flexGrow={1} pt={1}>
              <Button onClick={onActionTaken} endIcon={<RefreshOutlined />}>
                Refresh
              </Button>
            </Box>
          }
        />
      </Box>
    </>
  );
};

export default LockPayrollTimecardsWithExpensesDialogContent;
