import React from "react";

import { Divider, ListSubheader } from "@mui/material";

type IMenuSectionHeadingProps = {
  title: string;
};

const MenuSectionHeader = (props: IMenuSectionHeadingProps) => {
  const listSubheaderSX = {
    marginTop: "0px",
    marginBottom: "0px",
    backgroundColor: "",
    lineHeight: "32px",
  };

  return (
    <>
      <ListSubheader sx={listSubheaderSX}>
        <b>{props.title.toUpperCase()}</b>
      </ListSubheader>
      <Divider />
    </>
  );
};

export default MenuSectionHeader;
