import React, { useEffect, useState } from "react";
import { DialogContent, Skeleton } from "@mui/material";
import { getTimeEntry } from "api/DtTimeEntryPreviewApi";
import { DtTimeEntryPreviewSearchResult } from "types/dtTimeEntryPreview/DtTimeEntryPreviewSearchResult";
import { DtTimecard } from "types/dtTimeEntryPreview/dtTimecard";
import DtTimeEntryPreviewModalContextTable from "./DtTimeEntryPreviewModalContextTable";
import { useSnackbar } from "notistack";

type DtTimeEntryPreviewModalContentProps = {
  selectedTimesheet: DtTimeEntryPreviewSearchResult;
};

const DtTimeEntryPreviewModalContent = ({
  selectedTimesheet,
}: DtTimeEntryPreviewModalContentProps) => {
  const [timeEntry, setTimeEntry] = useState<DtTimecard | null>(null);
  const [loadingTimecard, setLoadingTimecard] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (selectedTimesheet) {
      setLoadingTimecard(true);
      getTimeEntry(selectedTimesheet)
        .then((result) => {
          setTimeEntry(result);
          setLoadingTimecard(false);
        })
        .catch(() => {
          enqueueSnackbar("Failed to load time entry", { variant: "error" });
        });
    }
  }, [selectedTimesheet]);

  return (
    <DialogContent>
      {loadingTimecard &&
        [...Array(10)].map((_) => <Skeleton key={_} variant="rectangular" sx={{ my: 2, mx: 1 }} />)}
      {!loadingTimecard && timeEntry && (
        <DtTimeEntryPreviewModalContextTable
          timecardTimeEntry={timeEntry}
          candidateName={selectedTimesheet.travelerName}
          hoursPerShift={selectedTimesheet.hoursPerShift}
          numberOfShifts={selectedTimesheet.numberOfShifts}
          scheduledHours={selectedTimesheet.scheduledHours}
        />
      )}
    </DialogContent>
  );
};

export default DtTimeEntryPreviewModalContent;
