import { ItemList } from "./itemList";

export enum UserStatus {
  Active = 1,
  MissingIdentityVerification = 2,
  MissingTermsAndConditions = 3,
  RegistrationNotCompleted = 4,
  NotFound = 5,
}

export type RoleName =
  | "SuperAdmin"
  | "Admin"
  | "Recruiter"
  | "SalesRep"
  | "RecruiterTeamLead"
  | "RecruiterAssistant";
export const rolesDescripcion: Record<RoleName, string> = {
  SuperAdmin: "Super Admin",
  Admin: "Admin",
  Recruiter: "Recruiter",
  SalesRep: "Sales Representative",
  RecruiterAssistant: "Recruiter Assistant",
  RecruiterTeamLead: "Recruiter Team Lead",
};

export enum UserType {
  Candidate = 0,
  Recruiter = 3,
  SuperAdmin = 2,
  Admin = 4,
  SalesRep = 5,
  RecruiterTeamLead = 6,
  RecruiterAssistant = 7,
}

export const UserTypeName = {
  [UserType.Candidate]: "Candidate",
  [UserType.Recruiter]: "Recruiter",
  [UserType.SuperAdmin]: "Super Admin",
  [UserType.Admin]: "Admin",
  [UserType.SalesRep]: "Sales Representative",
  [UserType.RecruiterTeamLead]: "Recruiter Team Lead",
  [UserType.RecruiterAssistant]: "Recruiter Assistant",
} as const;

export type UsersTableItem = ItemList & {
  email: string;
  bppEmails: string[];
  createdOrModifiedDate: string;
  status: UserStatus;
  isPayPeriodUnlocker: boolean;
  type: UserType;
  candidateId: string;
  externalId?: string;
  loginProvider: string;
};

export type UserGroup = {
  id?: string;
  name: string;
  canBePayPeriodUnlocker?: boolean;
};

export type UserRoleInformation = {
  userId?: string;
  groupId?: string;
  groups?: UserGroup[];
  isPayPeriodUnlocker?: boolean;
  assignedRecruiters?: string[];
};

export type UpdateUserInfo = {
  userId: string;
  newGroupId: string;
  oldGroupId: string;
  IsPayPeriodUnlocker: boolean;
  recruiters?: string[];
};
