import {
  Card,
  CardHeader,
  Checkbox,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Grid,
  Button,
} from "@mui/material";
import React, { useEffect } from "react";
import { ItemList } from "types/itemList";

function not(a: readonly ItemList[], b: readonly ItemList[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly ItemList[], b: readonly ItemList[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: readonly ItemList[], b: readonly ItemList[]) {
  return [...a, ...not(b, a)];
}

type RecruiterListSelectorProps = {
  items: ItemList[];
  selectedItems?: ItemList[];
  handleSelectedItems: (recruiters: ItemList[]) => void;
  itemsName?: string;
};

const ListSelector = ({
  items,
  selectedItems,
  handleSelectedItems,
  itemsName = "Recruiters/Recruiters Leads",
}: RecruiterListSelectorProps) => {
  const [checked, setChecked] = React.useState<readonly ItemList[]>([]);
  const [left, setLeft] = React.useState<readonly ItemList[]>(items);
  const right: ItemList[] = selectedItems ?? [];

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, selectedItems ?? []);

  const handleToggle = (value: ItemList) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: readonly ItemList[]) => intersection(checked, items).length;

  const handleToggleAll = (items: readonly ItemList[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    handleSelectedItems(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    handleSelectedItems(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  useEffect(() => {
    setLeft(not(items, right));
  }, [items, right]);

  const customList = (title: React.ReactNode, items: readonly ItemList[]) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: 325,
          height: 350,
          overflow: "auto",
        }}
        dense>
        {items.map((value: ItemList) => {
          const labelId = `transfer-list-all-item-${value.id}-label`;
          const bgColor = checked.indexOf(value) !== -1 ? "#50478b40" : "inherit";
          return (
            <ListItemButton
              key={value.id}
              onClick={handleToggle(value)}
              sx={{
                backgroundColor: bgColor,
                "&:hover": { backgroundColor: bgColor },
              }}>
              <ListItemText id={labelId} primary={value.name} />
            </ListItemButton>
          );
        })}
      </List>
    </Card>
  );

  return (
    <Grid marginTop={2} container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>{customList(itemsName, left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right">
            {">"}
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left">
            {"<"}
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(itemsName + " Selected", right)}</Grid>
    </Grid>
  );
};

export default ListSelector;
