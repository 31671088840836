import React, { useEffect, useState } from "react";
import { Avatar, IconButton, Tooltip } from "@mui/material";
import PayrollApi from "api/PayrollApi";
import { downloadBlob } from "commons/utils";
import { TimecardUploadSource } from "commons/Enums";

type FileAttachedIconProps = {
  timesheetId: string;
  uploadSource: TimecardUploadSource | null;
};

export default function FacilityReportAttachedIcon({
  timesheetId,
  uploadSource,
}: FileAttachedIconProps) {
  const [fillColor, setFillColor] = useState("");

  function downloadFile(url: string, fileName: string) {
    PayrollApi.downloadFile(url)
      .then((blob) => {
        const extension = blob.type.split("/")[1];
        downloadBlob(new Blob([blob]), `${fileName}.${extension}`).catch((e) => console.error(e));
      })
      .catch((error) => {
        alert(error);
      });
  }

  function downloadFacilityReport() {
    const url = `${process.env.REACT_APP_API_URL}/timesheet/facilityReport/${timesheetId}`;

    downloadFile(url, "Facility Report File");
  }

  useEffect(() => {
    let newClass = "";
    if (timesheetId && uploadSource !== null) {
      switch (uploadSource) {
        case TimecardUploadSource.Unknown:
        case TimecardUploadSource.AutoAttaching:
        case TimecardUploadSource.Spreadsheet:
          newClass = "auto-attaching";
          break;
        case TimecardUploadSource.Manual:
          newClass = "manual";
          break;
        case TimecardUploadSource.DigitalTimecards:
          newClass = "digital-timecards";
          break;

        default:
          break;
      }
    }

    setFillColor(newClass);
  }, [timesheetId, uploadSource]);

  return (
    <IconButton
      disabled={!uploadSource}
      sx={{
        padding: "0!important",
        margin: "0",
      }}
      onClick={downloadFacilityReport}
      size="large"
      data-testid="facility-report-attached-icon">
      <Tooltip title="A facility report has been uploaded" placement="top">
        <Avatar className={`file-attached-indicator ${fillColor}`}>
          <i className="material-icons-outlined">local_hospital</i>
        </Avatar>
      </Tooltip>
    </IconButton>
  );
}
