import React, { useEffect } from "react";
import PropTypes from "prop-types";
import TimecardSummary from "./TimecardSummary";
import TimecardEntry from "./TimecardEntry";
import BillingEntry from "../billing/BillingEntry";
import TimecardFile from "./TimecardFile";
import FacilityReportFile from "./FacilityReportFile";
import ConfirmDialog from "../shared/components/ConfirmDialog";
import LoggedUser from "../../commons/LoggedUser";
import { Button, Box } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import LateTimecardDialog from "../shared/components/LateTimecardDialog";
import TravelerDidNotWorkWeekDialog from "../shared/components/TravelerDidNotWorkWeekDialog";
import WorkOffIcon from "@mui/icons-material/WorkOff";
import moment from "moment";
import { useSelector } from "react-redux";
import { TimesheetFileTypes, TimesheetStatus } from "commons/Enums";
import TimecardNotes from "components/notes/TimecardNotes";
import TimesheetApi from "api/TimesheetApi";
import NoteTypeResolver from "../notes/NoteTypeResolver";
import TimecardDetailContentEditRequested from "./TimecardDetailContentEditRequested";
import TimecardDetailContentLate from "./TimecardDetailContentLate";

const TimecardDetailContent = (props) => {
  const {
    jobAssignment,
    onNoteChange,
    onNotifyTravelerChange,
    onNotesRetrieved,
    onTimecardFileUploaded,
    onTimecardUploadDelete,
    onFacilityReportFileUploaded,
    onFacilityReportFilesAdded,
    onTimecardEntriesChange,
    onTimecardAdjustmentEntriesChange,
    onAddAdjustment,
    onRemoveAdjustment,
    onBillingEntriesChange,
    onTaxableExpensesChange,
    onBonusExpensesChange,
    onNonTaxableExpensesChange,
    onTimecardDelete,
    onLateTimecard,
    onUndoLateTimecard,
    onTravelerDidNotWork,
    onExpenseTimecard,
    featureFlags,
    lateTimecardsReleased,
    previouslyLocked,
    onDidNotWorkReset,
    onAddNonTaxableAdjustment,
    onRemoveNonTaxableAdjustment,
    onNonTaxableAdjustmentsChange,
    onAcknowledgeNotes,
    onHandleOnExpenseChange,
    onEditRequest,
    onRateChangesAcknowledged,
    onCallOffsChange,
  } = props;

  const [isDeleteTimecardDialogOpen, setDeleteTimecardDialogOpen] = React.useState(false);
  const [isLateTimecardDialogOpen, setLateTimecardDialogOpen] = React.useState(false);
  const [isTravelerDidNotWorkDialogOpen, setTravelerDidNotWorkDialogOpen] = React.useState(false);

  useEffect(() => {
    setDeleteTimecardDialogOpen(false);
    setLateTimecardDialogOpen(false);
    setTravelerDidNotWorkDialogOpen(false);
  }, [jobAssignment]);

  const isRecruiterTeamLead = LoggedUser.isRecruiterTeamLead();
  const isAssistant = LoggedUser.isAssistant();
  const isRecruiter = LoggedUser.isRecruiter();
  const isSalesRep = LoggedUser.isSalesRep();
  const startDate = useSelector((state) => state.header.startDate);
  const endDate = useSelector((state) => state.header.endDate);

  const openDeleteTimecardDialog = () => {
    setDeleteTimecardDialogOpen(true);
  };

  const closeDeleteTimecardDialog = () => {
    setDeleteTimecardDialogOpen(false);
  };

  const openLateTimecardDialog = () => {
    setLateTimecardDialogOpen(true);
  };

  const closeLateTimecardDialog = () => {
    setLateTimecardDialogOpen(false);
  };

  const openTravelerDidNotWorkDialog = () => {
    setTravelerDidNotWorkDialogOpen(true);
  };

  const closeTravelerDidNotWorkDialog = () => {
    setTravelerDidNotWorkDialogOpen(false);
  };

  const isCurrentPayPeriod = () => {
    const startMoment = moment(startDate, "YYYY-MM-DD");
    const lastMoment = moment(endDate, "YYYY-MM-DD");
    const tsDate = moment(jobAssignment.timesheetStartDate, "YYYY-MM-DD");
    return moment(tsDate).isBetween(startMoment, lastMoment, null, "[)");
  };

  const acknowledgeNotes = () => {
    TimesheetApi.acknowledgeNotes(jobAssignment.id).then((response) => {
      if (response.ok) {
        onAcknowledgeNotes();
      }
    });
  };

  const getNotes = () => {
    if (!jobAssignment.notes) {
      TimesheetApi.getNotesByAssigment(jobAssignment.id).then((data) => {
        onNotesRetrieved(jobAssignment, data);
      });
    }
    return jobAssignment.notes;
  };

  let content;
  if (
    jobAssignment.isLate &&
    !lateTimecardsReleased &&
    isCurrentPayPeriod() &&
    !jobAssignment.isAdjustment &&
    (!previouslyLocked || (previouslyLocked && !jobAssignment.timecardUploadId))
  ) {
    content = <TimecardDetailContentLate onUndoLateTimecard={onUndoLateTimecard} />;
  } else if (jobAssignment.hasEditRequest) {
    content = (
      <TimecardDetailContentEditRequested
        jobAssignment={jobAssignment}
        onEditRequest={onEditRequest}
        onNoteChange={onNoteChange}
        onNotifyTravelerChange={onNotifyTravelerChange}
      />
    );
  } else if (jobAssignment.status === TimesheetStatus.FOR_REVIEW) {
    content = (
      <>
        <TimecardSummary
          assignment={jobAssignment}
          onNotesRetrieved={(notes) => onNotesRetrieved(jobAssignment, notes)}
          onNoteChange={onNoteChange}
          onNotifyTravelerChange={onNotifyTravelerChange}
          onFacilityReportFileUploaded={(files) => {
            onFacilityReportFileUploaded(files);
          }}
          onFacilityReportFilesAdded={(files) => {
            onFacilityReportFilesAdded(files);
          }}
          featureFlags={featureFlags}
          previouslyLocked={previouslyLocked}
          onRateChangesAcknowledged={onRateChangesAcknowledged}
          onCallOffsChange={onCallOffsChange}
        />
      </>
    );
  } else if (
    (jobAssignment.timecardUploadId || jobAssignment.hasExpenses || jobAssignment.isAdjustment) &&
    (jobAssignment.status === TimesheetStatus.RETURNED ||
      jobAssignment.status === TimesheetStatus.REJECTED ||
      jobAssignment.status === TimesheetStatus.DRAFT)
  ) {
    if (
      isRecruiterTeamLead ||
      isRecruiter ||
      isSalesRep ||
      isAssistant ||
      ((jobAssignment.isLate || jobAssignment.isAdjustment) && previouslyLocked)
    ) {
      content = (
        <TimecardSummary
          assignment={jobAssignment}
          onNotesRetrieved={(notes) => onNotesRetrieved(jobAssignment, notes)}
          onNoteChange={onNoteChange}
          onNotifyTravelerChange={onNotifyTravelerChange}
          onFacilityReportFileUploaded={(files) => {
            onFacilityReportFileUploaded(files);
          }}
          onFacilityReportFilesAdded={(files) => {
            onFacilityReportFilesAdded(files);
          }}
          featureFlags={featureFlags}
          previouslyLocked={previouslyLocked}
          onRateChangesAcknowledged={onRateChangesAcknowledged}
        />
      );
    } else {
      if (jobAssignment.hasToAcknowledgeNotes) {
        content = (
          <>
            <TimecardNotes
              inputAllowed={false}
              notes={getNotes()}
              noteTypeFilter={NoteTypeResolver.getNoteTypeFilterFromStatus(jobAssignment.status)}
            />
            <Button onClick={acknowledgeNotes} variant="contained">
              I acknowledge that I read the notes displayed
            </Button>
          </>
        );
      } else {
        content = (
          <TimecardEntry
            jobAssignment={jobAssignment}
            onAddAdjustment={onAddAdjustment}
            onRemoveAdjustment={onRemoveAdjustment}
            onAddNonTaxableAdjustment={onAddNonTaxableAdjustment}
            onRemoveNonTaxableAdjustment={onRemoveNonTaxableAdjustment}
            onTimecardEntriesChange={onTimecardEntriesChange}
            onTimecardAdjustmentEntriesChange={onTimecardAdjustmentEntriesChange}
            onTaxableExpensesChange={onTaxableExpensesChange}
            onBonusExpensesChange={onBonusExpensesChange}
            onNonTaxableExpensesChange={onNonTaxableExpensesChange}
            onTimecardFileUploaded={(files) => {
              onTimecardFileUploaded(files);
            }}
            onTimecardUploadDelete={onTimecardUploadDelete}
            onFacilityReportFileUploaded={(files) => {
              onFacilityReportFileUploaded(files);
            }}
            onFacilityReportFilesAdded={(files) => {
              onFacilityReportFilesAdded(files);
            }}
            onNotesRetrieved={(notes) => onNotesRetrieved(jobAssignment, notes)}
            onNoteChange={onNoteChange}
            onNotifyTravelerChange={onNotifyTravelerChange}
            featureFlags={featureFlags}
            onNonTaxableAdjustmentsChange={onNonTaxableAdjustmentsChange}
            onHandleOnExpenseChange={onHandleOnExpenseChange}
            onRateChangesAcknowledged={onRateChangesAcknowledged}
            onCallOffsChange={onCallOffsChange}
          />
        );
      }
    }
  } else if (jobAssignment.status === TimesheetStatus.APPROVED) {
    if ((jobAssignment.isLate || jobAssignment.isAdjustment) && previouslyLocked) {
      content = (
        <>
          <TimecardSummary
            assignment={jobAssignment}
            onNotesRetrieved={(notes) => onNotesRetrieved(jobAssignment, notes)}
            onNoteChange={onNoteChange}
            onNotifyTravelerChange={onNotifyTravelerChange}
            onFacilityReportFileUploaded={(files) => {
              onFacilityReportFileUploaded(files);
            }}
            onFacilityReportFilesAdded={(files) => {
              onFacilityReportFilesAdded(files);
            }}
            featureFlags={featureFlags}
            previouslyLocked={previouslyLocked}
            onHandleOnExpenseChange={onHandleOnExpenseChange}
            onRateChangesAcknowledged={onRateChangesAcknowledged}
            onCallOffsChange={onCallOffsChange}
          />
        </>
      );
    } else {
      content = (
        <TimecardSummary
          assignment={jobAssignment}
          onNotesRetrieved={(notes) => onNotesRetrieved(jobAssignment, notes)}
          onNoteChange={onNoteChange}
          onNotifyTravelerChange={onNotifyTravelerChange}
          onFacilityReportFileUploaded={(files) => {
            onFacilityReportFileUploaded(files);
          }}
          onFacilityReportFilesAdded={(files) => {
            onFacilityReportFilesAdded(files);
          }}
          featureFlags={featureFlags}
          onTimecardEntriesChange={onTimecardEntriesChange}
          onTimecardAdjustmentEntriesChange={onTimecardAdjustmentEntriesChange}
          onTaxableExpensesChange={onTaxableExpensesChange}
          onBonusExpensesChange={onBonusExpensesChange}
          onNonTaxableExpensesChange={onNonTaxableExpensesChange}
          onBillingEntriesChange={onBillingEntriesChange}
          onAddAdjustment={onAddAdjustment}
          onRemoveAdjustment={onRemoveAdjustment}
          previouslyLocked={previouslyLocked}
          onAddNonTaxableAdjustment={onAddNonTaxableAdjustment}
          onRemoveNonTaxableAdjustment={onRemoveNonTaxableAdjustment}
          onNonTaxableAdjustmentsChange={onNonTaxableAdjustmentsChange}
          onHandleOnExpenseChange={onHandleOnExpenseChange}
          onRateChangesAcknowledged={onRateChangesAcknowledged}
          onCallOffsChange={onCallOffsChange}
        />
      );
    }
  } else if (jobAssignment.status === TimesheetStatus.NOTIMECARD) {
    content = (
      <TimecardSummary
        assignment={jobAssignment}
        onNotesRetrieved={(notes) => onNotesRetrieved(jobAssignment, notes)}
        onNoteChange={onNoteChange}
        onNotifyTravelerChange={onNotifyTravelerChange}
        onFacilityReportFileUploaded={(files) => {
          onFacilityReportFileUploaded(files);
        }}
        onFacilityReportFilesAdded={(files) => {
          onFacilityReportFilesAdded(files);
        }}
        featureFlags={featureFlags}
        previouslyLocked={previouslyLocked}
        onDidNotWorkReset={onDidNotWorkReset}
        onHandleOnExpenseChange={onHandleOnExpenseChange}
      />
    );
  } else if (
    jobAssignment.status === TimesheetStatus.DRAFT ||
    jobAssignment.status === TimesheetStatus.REJECTED ||
    (jobAssignment.status === TimesheetStatus.RETURNED && !jobAssignment.timecardUploadId)
  ) {
    if (isRecruiterTeamLead || isRecruiter || isSalesRep || isAssistant) {
      content = (
        <TimecardSummary
          assignment={jobAssignment}
          onNotesRetrieved={(notes) => onNotesRetrieved(jobAssignment, notes)}
          onNoteChange={onNoteChange}
          onNotifyTravelerChange={onNotifyTravelerChange}
          onFacilityReportFileUploaded={(files) => {
            onFacilityReportFileUploaded(files);
          }}
          onFacilityReportFilesAdded={(files) => {
            onFacilityReportFilesAdded(files);
          }}
          featureFlags={featureFlags}
          previouslyLocked={previouslyLocked}
          onHandleOnExpenseChange={onHandleOnExpenseChange}
          onRateChangesAcknowledged={onRateChangesAcknowledged}
        />
      );
    } else {
      const detailContent = (
        <>
          <TimecardFile
            timesheetId={jobAssignment.id}
            timecardUploadId={jobAssignment.timecardUploadId}
            onFileUploaded={onTimecardFileUploaded}
            uploading={jobAssignment.uploading & TimesheetFileTypes.TIMECARD}
            isDigitalTimecardEntry={jobAssignment.isDigitalTimecardEntry}
          />
          <FacilityReportFile
            timesheetId={jobAssignment.id}
            facilityReportUploadId={jobAssignment.facilityReportUploadId}
            onFileUploaded={onFacilityReportFileUploaded}
            onFilesAdded={onFacilityReportFilesAdded}
            uploading={jobAssignment.uploading & TimesheetFileTypes.FACILITY_REPORT}
            isDigitalTimecardEntry={jobAssignment.isDigitalTimecardEntry}
          />
        </>
      );

      content = (
        <>
          {!!jobAssignment.timecardUploadId && (
            <TimecardSummary
              assignment={jobAssignment}
              onNotesRetrieved={(notes) => onNotesRetrieved(jobAssignment, notes)}
              onNoteChange={onNoteChange}
              onNotifyTravelerChange={onNotifyTravelerChange}
              onFacilityReportFileUploaded={(files) => {
                onFacilityReportFileUploaded(files);
              }}
              onFacilityReportFilesAdded={(files) => {
                onFacilityReportFilesAdded(files);
              }}
              featureFlags={featureFlags}
              previouslyLocked={previouslyLocked}
              onHandleOnExpenseChange={onHandleOnExpenseChange}
              onRateChangesAcknowledged={onRateChangesAcknowledged}
              onCallOffsChange={onCallOffsChange}
            />
          )}
          <div className="timecard-detail-pane-question">{detailContent}</div>
          <div className="timecard-detail-pane-timecardoptions">
            <Button onClick={openTravelerDidNotWorkDialog}>
              <WorkOffIcon variant="outlined" color="primary" />
              Did Not Work or <br /> Did Not Submit
            </Button>
            <TravelerDidNotWorkWeekDialog
              open={isTravelerDidNotWorkDialogOpen}
              onClose={closeTravelerDidNotWorkDialog}
              onTravelerDidNotWork={onTravelerDidNotWork}
              onExpenseTimecard={onExpenseTimecard}
              timesheetId={jobAssignment.id}
            />
          </div>
          {!jobAssignment.isLate ? (
            <div className="timecard-detail-pane-timecardoptions">
              <Button onClick={openLateTimecardDialog}>
                <WatchLaterIcon variant="outlined" color="primary" />
                Late Timecard
              </Button>
              <LateTimecardDialog
                open={isLateTimecardDialogOpen}
                onClose={closeLateTimecardDialog}
                onLateTimecard={onLateTimecard}
              />
            </div>
          ) : null}
          <div className="timecard-detail-pane-timecardoptions">
            <Button onClick={openDeleteTimecardDialog}>
              <DeleteIcon variant="outlined" color="primary" />
              Delete Timecard
            </Button>
            <ConfirmDialog
              isOpen={isDeleteTimecardDialogOpen}
              onClose={closeDeleteTimecardDialog}
              onConfirm={onTimecardDelete}
              content="Are you sure you want to delete this Timecard?"
              dialogTitle="Delete Timecard?"
            />
          </div>
        </>
      );
    }
  } else if (
    jobAssignment.status === TimesheetStatus.RETURNED ||
    jobAssignment.status === TimesheetStatus.REJECTED
  ) {
    if (
      isRecruiterTeamLead ||
      isRecruiter ||
      isSalesRep ||
      isAssistant ||
      ((jobAssignment.isLate || jobAssignment.isAdjustment) && previouslyLocked)
    ) {
      content = (
        <>
          <TimecardSummary
            assignment={jobAssignment}
            onNotesRetrieved={(notes) => onNotesRetrieved(jobAssignment, notes)}
            onNoteChange={onNoteChange}
            onNotifyTravelerChange={onNotifyTravelerChange}
            onFacilityReportFileUploaded={(files) => {
              onFacilityReportFileUploaded(files);
            }}
            onFacilityReportFilesAdded={(files) => {
              onFacilityReportFilesAdded(files);
            }}
            featureFlags={featureFlags}
            previouslyLocked={previouslyLocked}
            handleOnExpenseChange={onHandleOnExpenseChange}
            onRateChangesAcknowledged={onRateChangesAcknowledged}
            onCallOffsChange={onCallOffsChange}
          />
        </>
      );
    } else {
      content = (
        <TimecardEntry
          jobAssignment={jobAssignment}
          onTimecardEntriesChange={onTimecardEntriesChange}
          onTimecardAdjustmentEntriesChange={onTimecardAdjustmentEntriesChange}
          onTaxableExpensesChange={onTaxableExpensesChange}
          onNonTaxableExpensesChange={onNonTaxableExpensesChange}
          onTimecardFileUploaded={(files) => {
            onTimecardFileUploaded(files);
          }}
          onTimecardUploadDelete={onTimecardUploadDelete}
          onFacilityReportFileUploaded={(files) => {
            onFacilityReportFileUploaded(files);
          }}
          onFacilityReportFilesAdded={(files) => {
            onFacilityReportFilesAdded(files);
          }}
          onNotesRetrieved={(notes) => onNotesRetrieved(jobAssignment, notes)}
          onNoteChange={onNoteChange}
          onNotifyTravelerChange={onNotifyTravelerChange}
          featureFlags={featureFlags}
          onHandleOnExpenseChange={onHandleOnExpenseChange}
          onRateChangesAcknowledged={onRateChangesAcknowledged}
          onCallOffsChange={onCallOffsChange}
        />
      );
    }
  } else if (jobAssignment.status === TimesheetStatus.BILLING) {
    content = (
      <BillingEntry
        jobAssignment={jobAssignment}
        onBillingEntriesChange={onBillingEntriesChange}
        onTimecardFileUploaded={(files) => {
          onTimecardFileUploaded(files);
        }}
        onFacilityReportFileUploaded={(files) => {
          onFacilityReportFileUploaded(files);
        }}
        onFacilityReportFilesAdded={(files) => {
          onFacilityReportFilesAdded(files);
        }}
        onNotesRetrieved={(notes) => onNotesRetrieved(jobAssignment, notes)}
        onNoteChange={onNoteChange}
        onNotifyTravelerChange={onNotifyTravelerChange}
        featureFlags={featureFlags}
        readOnly={
          isSalesRep || ((jobAssignment.isLate || jobAssignment.isAdjustment) && previouslyLocked)
        }
      />
    );
  }

  return <>{content}</>;
};

TimecardDetailContent.propTypes = {
  jobAssignment: PropTypes.object.isRequired,
  onNoteChange: PropTypes.func.isRequired,
  onNotifyTravelerChange: PropTypes.func.isRequired,
  onNotesRetrieved: PropTypes.func.isRequired,
  onTimecardFileUploaded: PropTypes.func.isRequired,
  onTimecardUploadDelete: PropTypes.func,
  onFacilityReportFileUploaded: PropTypes.func.isRequired,
  onFacilityReportFilesAdded: PropTypes.func.isRequired,
  onTimecardEntriesChange: PropTypes.func.isRequired,
  onTimecardAdjustmentEntriesChange: PropTypes.func,
  onAddAdjustment: PropTypes.func,
  onRemoveAdjustment: PropTypes.func,
  onBillingEntriesChange: PropTypes.func.isRequired,
  onTaxableExpensesChange: PropTypes.func.isRequired,
  onBonusExpensesChange: PropTypes.func.isRequired,
  onNonTaxableExpensesChange: PropTypes.func.isRequired,
  featureFlags: PropTypes.array.isRequired,
  onLateTimecard: PropTypes.func.isRequired,
  onUndoLateTimecard: PropTypes.func.isRequired,
  onTimecardDelete: PropTypes.func.isRequired,
  onTravelerDidNotWork: PropTypes.func.isRequired,
  onExpenseTimecard: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func,
  lateTimecardsReleased: PropTypes.bool,
  previouslyLocked: PropTypes.bool,
  onDidNotWorkReset: PropTypes.func,
  onAddNonTaxableAdjustment: PropTypes.func,
  onRemoveNonTaxableAdjustment: PropTypes.func,
  onNonTaxableAdjustmentsChange: PropTypes.func,
  onAcknowledgeNotes: PropTypes.func,
  onHandleOnExpenseChange: PropTypes.func,
  onEditRequest: PropTypes.func,
  onRateChangesAcknowledged: PropTypes.func,
  onCallOffsChange: PropTypes.func,
};

export default TimecardDetailContent;
