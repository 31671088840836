import React, { useState } from "react";

import CanRenderChildren from "components/shared/functions/CanRenderChildren";
import MenuDropdownLink from "./MenuDropdownLink";
import MenuSectionHeader from "./MenuSectionHeader";
import usePermissions from "../../../../hooks/usePermissions";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Button, CircularProgress, List, Menu } from "@mui/material";

type IProps = {
  isLoading: boolean;
  onClickUltiproReportLink: () => void;
  onClickLateUltiproReportLink: () => void;
  onClickPayrollReportLink: () => void;
  onClickLatePayrollReportLink: () => void;
  onClickBillingReportLink: () => void;
  onClickLateBillingReportLink: () => void;
  onClickAdjustmentBillingReportLink: () => void;
  onClickTimecardImportLink: () => void;
  onClickExchangeImportLink: () => void;
};

const ReportsDropdownMenu = (props: IProps) => {
  const [reportMenuEl, setReportMenuEl] = useState<HTMLButtonElement | null>(null);
  const reportMenuOpen = Boolean(reportMenuEl);

  if (props.isLoading) {
    return <CircularProgress />;
  }

  const handleReportMenuButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setReportMenuEl(event.currentTarget);
  };

  const handleCloseReportMenu = () => {
    setReportMenuEl(null);
  };

  const handleOpenUltiproReport = () => {
    handleCloseReportMenu();
    props.onClickUltiproReportLink();
  };

  const handleOpenLateUltiproReport = () => {
    handleCloseReportMenu();
    props.onClickLateUltiproReportLink();
  };

  const handleOpenPayrollReport = () => {
    handleCloseReportMenu();
    props.onClickPayrollReportLink();
  };

  const handleOpenLatePayrollReport = () => {
    handleCloseReportMenu();
    props.onClickLatePayrollReportLink();
  };

  const handleOpenBillingReport = () => {
    handleCloseReportMenu();
    props.onClickBillingReportLink();
  };

  const handleOpenLateBillingReport = () => {
    handleCloseReportMenu();
    props.onClickLateBillingReportLink();
  };

  const handleOpenAdjustmentBillingReport = () => {
    handleCloseReportMenu();
    props.onClickAdjustmentBillingReportLink();
  };

  const handleOpenTimecardImport = () => {
    handleCloseReportMenu();
    props.onClickTimecardImportLink();
  };

  const handleOpenExchangeImport = () => {
    handleCloseReportMenu();
    props.onClickExchangeImportLink();
  };

  const canDownloadTriagePlusReportPermission = usePermissions()["canDownloadTriagePlusReport"];
  const canDownloadExchangeReportPermission = usePermissions()["canDownloadExchangeReport"];

  return (
    <CanRenderChildren permissionName="canSeeButtonsProfile">
      <Button
        variant="contained"
        title="Reports"
        id="report-menu-button"
        aria-controls={reportMenuOpen ? "report-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={reportMenuOpen ? "true" : undefined}
        sx={{
          width: "40px",
          height: "40px",
          padding: "0",
          marginLeft: "8px",
          marginRight: "10px",
          minWidth: "40px",
          cursor: "pointer",
        }}
        onClick={handleReportMenuButtonClick}>
        <MoreVertIcon />
      </Button>

      <Menu
        id="report-menu"
        anchorEl={reportMenuEl}
        open={reportMenuOpen}
        onClose={handleCloseReportMenu}
        MenuListProps={{
          "aria-labelledby": "report-menu-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}>
        <List dense sx={{ p: 0, m: 0, paddingTop: "0" }}>
          {/* Payroll */}
          <MenuSectionHeader title="Payroll" />
          <MenuDropdownLink title="Ultipro" onClick={handleOpenUltiproReport} />
          <MenuDropdownLink title="Late Ultipro" onClick={handleOpenLateUltiproReport} />
          <MenuDropdownLink title="Payroll" onClick={handleOpenPayrollReport} />
          <MenuDropdownLink title="Late Payroll" onClick={handleOpenLatePayrollReport} />

          {/* Billing */}
          <MenuSectionHeader title="Billing" />
          <MenuDropdownLink title="Billing" onClick={handleOpenBillingReport} />
          <MenuDropdownLink title="Late Billing" onClick={handleOpenLateBillingReport} />
          <MenuDropdownLink
            title="Adjustment Billing"
            onClick={handleOpenAdjustmentBillingReport}
          />

          {/* MSP */}
          {(canDownloadTriagePlusReportPermission || canDownloadExchangeReportPermission) && (
            <>
              <MenuSectionHeader title="MSP" />
              {canDownloadTriagePlusReportPermission && (
                <MenuDropdownLink title="Timecard Import" onClick={handleOpenTimecardImport} />
              )}
              {canDownloadExchangeReportPermission && (
                <MenuDropdownLink title="Exchange Import" onClick={handleOpenExchangeImport} />
              )}
            </>
          )}
        </List>
      </Menu>
    </CanRenderChildren>
  );
};

export default ReportsDropdownMenu;
