import React, { useCallback } from "react";
import { Checkbox, Chip, ListItemText, MenuItem, Select } from "@mui/material";

type MultiSelectChipProps = {
  label: string;
  options: any[];
  optionsSelected: any[];
  onOptionSelected: (event: any) => void;
  onOptionDeletedChip: (value: any) => void;
  optionLabel?: string;
  optionValue?: string;
};

const MultiSelectChip = ({
  label,
  options,
  optionsSelected,
  onOptionSelected,
  onOptionDeletedChip,
  optionLabel,
  optionValue,
}: MultiSelectChipProps) => {
  const isSelected = useCallback(
    (option) => {
      if (optionValue) {
        const optionFound = optionsSelected.find((o) => o === option[optionValue]);

        return optionFound !== undefined;
      } else {
        return optionsSelected?.indexOf(option) > -1;
      }
    },
    [optionsSelected, optionValue]
  );

  return (
    <Select
      label={label}
      autoWidth={true}
      multiple={true}
      value={optionsSelected}
      onChange={onOptionSelected}
      onClose={() => {
        setTimeout(() => {
          (document.activeElement as any)?.blur();
        }, 0);
      }}
      renderValue={(selected) => (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {selected.map((value) => (
            <Chip
              key={value}
              label={
                optionLabel
                  ? options.find((o) => o[optionValue as never] === value)
                    ? options.find((o) => o[optionValue as never] === value)[optionLabel]
                    : value
                  : value
              }
              onDelete={() => {
                onOptionDeletedChip(value);
              }}
              size="small"
              style={{ margin: 2 }}
              onMouseDown={(event) => {
                event.stopPropagation();
              }}
            />
          ))}
        </div>
      )}>
      {options.map((option) => (
        <MenuItem
          key={optionValue ? option[optionValue] : option}
          value={optionValue ? option[optionValue] : option}>
          <Checkbox checked={isSelected(option)} color="primary" />
          <ListItemText primary={optionLabel ? option[optionLabel] : option} />
        </MenuItem>
      ))}
    </Select>
  );
};

export default MultiSelectChip;
