import { Moment } from "moment";
import { msalApiFetch } from "../commons/authConfig";
import { downloadBlob } from "../commons/utils";

const baseUrl = process.env.REACT_APP_API_URL;

export default {
  // Api to download RawData report
  downloadPayrollRawDataReport(fromDate: Moment, lateTimecards: boolean) {
    const url = `${baseUrl}/payroll/rawdatareport`;
    return (
      msalApiFetch(url, {
        method: "POST",
        body: JSON.stringify({
          FromDate: fromDate.format("MM/DD/YYYY"),
          LateTimecards: lateTimecards,
        }),
      })
        // 1. Convert the data into 'blob'
        .then((response) => response.blob())
    );
  },
  downloadBillingRawDataReport(fromDate: Moment, lateTimecards: boolean) {
    const url = `${baseUrl}/billing/rawdatareport`;
    return (
      msalApiFetch(url, {
        method: "POST",
        body: JSON.stringify({
          FromDate: fromDate.format("MM/DD/YYYY"),
          LateTimecards: lateTimecards,
        }),
      })
        // 1. Convert the data into 'blob'
        .then((response) => response.blob())
    );
  },
  downloadPayrollRecruiterReport(
    recruiterId: string,
    lateTimecards: boolean,
    adjustmentTimecards: boolean,
    onlyAdjustedLines: boolean,
    isRtg: boolean
  ) {
    const url = `${baseUrl}/payroll/report`;
    return (
      msalApiFetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          RecruiterId: recruiterId,
          LateTimecards: lateTimecards,
          AdjustmentTimecards: adjustmentTimecards,
          OnlyAdjustedLines: onlyAdjustedLines,
          IsRtg: isRtg,
        }),
      })
        // 1. Convert the data into 'blob'
        .then((response) => response.blob())
    );
  },
  downloadBillingRecruiterReport(recruiterId: string, lateTimecards: boolean) {
    const url = `${baseUrl}/billing/report`;
    return (
      msalApiFetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          RecruiterId: recruiterId,
          LateTimecards: lateTimecards,
        }),
      })
        // 1. Convert the data into 'blob'
        .then((response) => response.blob())
    );
  },
  downloadCombinedRecruiterReport(recruiterId: string, lateTimecards: boolean) {
    const url = `${baseUrl}/combined/report`;
    return (
      msalApiFetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          RecruiterId: recruiterId,
          LateTimecards: lateTimecards,
        }),
      })
        // 1. Convert the data into 'blob'
        .then((response) => response.blob())
    );
  },
  downloadPayrollPaycorReport(reportType: string, fromDate: Moment, toDate: Moment) {
    const url = `${baseUrl}/payroll/paycor`;
    return (
      msalApiFetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ReportType: reportType,
          FromDate: fromDate.format("MM/DD/YYYY"),
          ToDate: toDate.format("MM/DD/YYYY"),
        }),
      })
        // 1. Convert the data into 'blob'
        .then((response) => response.blob())
    );
  },
  downloadFile(url: string) {
    return msalApiFetch(url).then((response) => {
      return response.blob();
    });
  },
  downloadTimecardFile(timecardUploadId: string, filename: string) {
    const url = `${process.env.REACT_APP_API_URL}/timesheet/timecard/${timecardUploadId}`;

    this.downloadFile(url)
      .then((blob) => {
        const extension = blob.type.split("/")[1];
        downloadBlob(new Blob([blob]), `${filename}.${extension}`).catch((error) => alert(error));
      })
      .catch((error) => alert(error));
  },

  openTimecardFile(timecardUploadId: string) {
    const url = `${process.env.REACT_APP_API_URL}/timesheet/timecard/${timecardUploadId}`;

    this.downloadFile(url)
      .then((blob) => {
        const _url = window.URL.createObjectURL(blob);
        window.open(_url, "_blank");
      })
      .catch((error) => alert(error));
  },

  deleteTimecardUpload(timesheetId: string) {
    const url = `${baseUrl}/timesheet/${timesheetId}/uploads/timecard`;
    return msalApiFetch(url, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        if (res.ok) {
          return res;
        }
        throw res;
      })
      .catch((error) => {
        throw error;
      });
  },

  validateLockRecruiterPayroll(dealsheetId: string) {
    const url = `${baseUrl}/payroll/lock/recruiterValidate`;
    return msalApiFetch(url, {
      method: "POST",
      body: JSON.stringify({
        DealsheetId: dealsheetId,
      }),
    }).then((data) => {
      if (!data.ok) throw data;
      return data.json();
    });
  },
};
