import React, { useState } from "react";
import { Button, Collapse, IconButton, TableCell, TableRow } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import {
  VmsTableFacilityTravelerItem,
  VmsTableFacilityTravelerTimesheetItem,
} from "types/vms/vmsTableItem";
import { Link } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import VmsProfileSummarizedHours from "./VmsProfileSummarizedHours";
import PayrollApi from "api/PayrollApi";
import { downloadBlob } from "commons/utils";
import { getSummarizedHours } from "./utils";

type VmsProfileTableRowTravelerProps = {
  traveler: VmsTableFacilityTravelerItem;
};

const VmsProfileTableRowTraveler = ({ traveler }: VmsProfileTableRowTravelerProps) => {
  const [open, setOpen] = useState(false);
  const headerState = useSelector((state: RootState) => state.header);

  const timesheetsCount = traveler.timesheets.length;

  const isCurrentPayPeriod = (period: string) => {
    const startDate = period.substring(0, 10);
    return new Date(startDate).getTime() === new Date(headerState.startDate).getTime();
  };

  const formatDate = (date: string) => {
    return moment(new Date(date)).format("MM/DD/YYYY");
  };

  const getTravelerHistoryLink = (timesheet: VmsTableFacilityTravelerTimesheetItem) => {
    let url = `/traveler-history/${timesheet.jobAssignmentId}`;

    if (timesheet.regularHours > 0 || timesheet.overtimeHours > 0) {
      url = `${url}?t=${timesheet.timesheetId}`;
    }

    return url;
  };

  const regularHours = getSummarizedHours(traveler, "regularHours");

  const overtimeHours = getSummarizedHours(traveler, "overtimeHours");

  const onCallHours = getSummarizedHours(traveler, "oncallHours");

  const callBackHours = getSummarizedHours(traveler, "callBackHours");

  const holidayHours = getSummarizedHours(traveler, "holidayHours");

  const nonBillableOrientationHours = getSummarizedHours(traveler, "nonBillableHours");

  function downloadFacilityReport(timesheetId: string) {
    const url = `${process.env.REACT_APP_API_URL}/timesheet/facilityReport/${timesheetId}`;
    PayrollApi.downloadFile(url)
      .then((blob) => {
        const extension = blob.type.split("/")[1];
        downloadBlob(new Blob([blob]), `${"Facility Report File"}.${extension}`).catch((e) =>
          console.error(e)
        );
      })
      .catch((error) => {
        alert(error);
      });
  }

  return (
    <>
      <TableRow>
        <TableCell colSpan={8} sx={{ pl: 8, cursor: "pointer" }} onClick={() => setOpen(!open)}>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
          </IconButton>
          <b>
            {traveler.travelerName} ({timesheetsCount})
          </b>
          <VmsProfileSummarizedHours
            regularHours={regularHours}
            overtimeHours={overtimeHours}
            onCallHours={onCallHours}
            callBackHours={callBackHours}
            holidayHours={holidayHours}
            nonBillableOrientationHours={nonBillableOrientationHours}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={8} sx={{ p: !open ? 0 : undefined }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <TableRow>
              <TableCell>Period</TableCell>
              <TableCell sx={{ textAlign: "center" }}>Reg Hours</TableCell>
              <TableCell sx={{ textAlign: "center" }}>OT/DT Hours</TableCell>
              <TableCell sx={{ textAlign: "center" }}>On Call</TableCell>
              <TableCell sx={{ textAlign: "center" }}>Call Back</TableCell>
              <TableCell sx={{ textAlign: "center" }}>Holiday</TableCell>
              <TableCell sx={{ textAlign: "center" }}>Non-Billable Orientation</TableCell>
              <TableCell></TableCell>
            </TableRow>
            {traveler.timesheets.map((timesheet) => (
              <TableRow key={timesheet.timesheetId}>
                <TableCell sx={{ width: "1%", whiteSpace: "nowrap" }}>
                  {timesheet.period}
                  {timesheet.isAdjustment && (
                    <InfoOutlinedIcon
                      htmlColor="#50478b"
                      titleAccess="Adjustment Timecard"
                      style={{
                        display: "inline-flex",
                        marginRight: "5px",
                        verticalAlign: "middle",
                        marginLeft: "5px",
                        width: "16px",
                        height: "16px",
                      }}
                    />
                  )}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {timesheet.regularHours.toFixed(2)}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {timesheet.overtimeHours.toFixed(2)}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {timesheet.oncallHours.toFixed(2)}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {timesheet.callBackHours.toFixed(2)}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {timesheet.holidayHours.toFixed(2)}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {timesheet.nonBillableHours.toFixed(2)}
                </TableCell>
                <TableCell>
                  {isCurrentPayPeriod(timesheet.period) ? (
                    <Button
                      component={Link}
                      to={{
                        pathname: `/home/${timesheet.recruiterId}/${
                          timesheet.timesheetId
                        }/${formatDate(headerState.startDate)}/${formatDate(headerState.endDate)}`,
                      }}
                      target="_blank">
                      Timesheet
                    </Button>
                  ) : (
                    <Button component={Link} to={getTravelerHistoryLink(timesheet)} target="_blank">
                      Timesheet
                    </Button>
                  )}

                  {timesheet.facilityReportUploadId && (
                    <Button onClick={() => downloadFacilityReport(timesheet.timesheetId)}>
                      Facility Report
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default VmsProfileTableRowTraveler;
