import React, { useState, useCallback, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TravelerTimecardsHistory from "./TravelerTimecardsHistory";
import { CircularProgress } from "@mui/material";
import { TabPanel, TabContext } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import TravelerHistoryTimecardDetails from "./TravelerHistoryTimecardDetails";
import { CalendarToday } from "@mui/icons-material";
import { JobAssignmentCardHistory } from "types/history/JobAssignmentCardHistory";

type TravelerHistoryTimecardsProps = {
  timecards: JobAssignmentCardHistory[];
  refreshJobAssignment: () => void;
  preSelectedTimesheetId?: string;
};

type TabType = {
  value: string;
  label: string;
  component: JSX.Element;
};

export default function TravelerHistoryTimecards({
  timecards,
  refreshJobAssignment,
  preSelectedTimesheetId,
}: TravelerHistoryTimecardsProps) {
  const [selectedTab, setSelectedTab] = useState("0");
  const [tabs, setTabs] = useState<TabType[]>([]);

  const handleChange = useCallback((_event, newValue) => {
    setSelectedTab(newValue);
  }, []);

  const formatDate = (date: string) => {
    return moment(new Date(date)).format("MM/DD/YYYY");
  };

  const createNewTab = (timesheet: JobAssignmentCardHistory) => {
    const label = `${formatDate(timesheet.timeSheetStartDate)} - ${formatDate(
      timesheet.timeSheetEndDate
    )}`;

    const existingTab = tabs.find((tab) => tab.label === label);
    if (existingTab !== undefined) {
      setSelectedTab(existingTab.value);
      return;
    }

    const newTab = {
      value: label,
      label: label,
      component: (
        <TravelerHistoryTimecardDetails
          partialTimesheetInfo={timesheet}
          refreshJobAssignment={refreshJobAssignment}
        />
      ),
    };

    setTabs([...tabs, newTab]);
    setSelectedTab(label);
  };

  const removeTab = (value: string) => {
    if (value === selectedTab) {
      setSelectedTab("0");
    }
    const newTabs = tabs.filter((tab) => tab.value !== value);
    setTabs(newTabs);
  };

  useEffect(() => {
    if (preSelectedTimesheetId) {
      const timesheet = timecards.find((t) => t.timesheetId === preSelectedTimesheetId);
      if (timesheet && timesheet.timecardType !== "Did Not Work") {
        createNewTab(timesheet);
      }
    }
  }, [preSelectedTimesheetId]);

  return (
    <TabContext value={selectedTab}>
      <Tabs
        sx={{
          zIndex: 50,
          background: "#fafafa",
        }}
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable tabs">
        <Tab label="History" value="0" />
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            label={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <div
                  style={{
                    marginRight: 4,
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "24px",
                    height: "24px",
                  }}>
                  <CalendarToday fontSize="small" />
                </div>
                <span>{tab.label}</span>
                <div
                  style={{
                    marginLeft: 4,
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "24px",
                    height: "24px",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    removeTab(tab.value);
                  }}>
                  <CloseIcon fontSize="small" />
                </div>
              </div>
            }
            value={tab.value}
          />
        ))}
      </Tabs>
      <TabPanel value="0">
        {timecards ? (
          <TravelerTimecardsHistory timecards={timecards} createNewTab={createNewTab} />
        ) : (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        )}
      </TabPanel>
      {tabs.map((tab) => (
        <TabPanel key={tab.value} value={tab.value}>
          {tab.component}
        </TabPanel>
      ))}
    </TabContext>
  );
}
