import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
} from "@mui/material";
import TravelerSearch from "components/history/TravelerSearch";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DT_PREVIEW_FILTERS_CHANGED_INCLUDE_NEXT,
  DT_PREVIEW_FILTERS_CHANGED_TRAVELER,
  TITLE_CHANGED,
} from "redux/actions/actionTypes";
import { TravelerState } from "types/traveler";
import SearchIcon from "@mui/icons-material/Search";
import DtTimeEntryPreviewTravelerInfo from "./DtTimeEntryPreviewTravelerInfo";
import DtTimeEntryPreviewModalContent from "./DtTimeEntryPreviewModalContent";
import { useSnackbar } from "notistack";
import { getTimesheetsToPreview } from "api/DtTimeEntryPreviewApi";
import { DtTimeEntryPreviewSearchResult } from "types/dtTimeEntryPreview/DtTimeEntryPreviewSearchResult";
import DtTimeEntrySkeletonSearch from "./DtTimeEntrySkeletonSearch";

const DtTimeEntryPreview = () => {
  const dispatch = useDispatch();
  const currentFilters = useSelector((state: RootState) => state.dtTimeEntryPreview);
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  const [timesheets, setTimesheets] = useState<DtTimeEntryPreviewSearchResult[] | null>(null);
  const [isSearching, setIsSearching] = useState(false);
  const [selectedTimesheet, setSelectedTimesheet] = useState<DtTimeEntryPreviewSearchResult | null>(
    null
  );

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch({ type: TITLE_CHANGED, title: "DT Time Entry Preview" });
  }, []);

  const handleTravelerChange = (_: SyntheticEvent<Element, Event>, newValue: TravelerState) => {
    dispatch({
      type: DT_PREVIEW_FILTERS_CHANGED_TRAVELER,
      travelers: newValue,
    });
  };

  const handleToggleChange = (_: SyntheticEvent<Element, Event>, newValue: boolean) => {
    dispatch({
      type: DT_PREVIEW_FILTERS_CHANGED_INCLUDE_NEXT,
      includeNextPayPeriod: newValue,
    });
  };

  const onSearch = async () => {
    if (!currentFilters.travelers) {
      enqueueSnackbar("Please select a traveler", { variant: "error" });
      return;
    }

    setIsSearching(true);
    let timesheets: DtTimeEntryPreviewSearchResult[] = [];
    try {
      timesheets = await getTimesheetsToPreview(
        currentFilters.travelers.travelerIds,
        currentFilters.includeNextPayPeriod
      );
    } catch (error) {
      enqueueSnackbar("Failed to search for timesheets", { variant: "error" });
      setTimesheets(null);
      return;
    }
    setIsSearching(false);

    if (timesheets.length === 0) {
      enqueueSnackbar("No timesheets found", { variant: "info" });
      setTimesheets(null);
    }

    setTimesheets(timesheets);
  };

  const onSeeMore = (timesheetId: string) => {
    const selectedTimesheet = timesheets?.find((timesheet) => timesheet.id === timesheetId);

    if (!selectedTimesheet) {
      enqueueSnackbar("Timesheet not found", { variant: "error" });
      return;
    }

    setSelectedTimesheet(selectedTimesheet);
    setIsAlertDialogOpen(true);
  };

  return (
    <Box mx={2} my={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <TravelerSearch
            currentTraveler={currentFilters.travelers}
            handleChange={handleTravelerChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={currentFilters.includeNextPayPeriod}
                onChange={handleToggleChange}
              />
            }
            label="Include next pay period"
            style={{ marginLeft: 5 }}
          />
        </Grid>
        <Grid item xs={12} md={5} lg={3}>
          <Button variant="contained" color="primary" startIcon={<SearchIcon />} onClick={onSearch}>
            Search
          </Button>
        </Grid>
      </Grid>

      {isSearching && <DtTimeEntrySkeletonSearch />}

      {!isSearching && timesheets && (
        <Grid container spacing={2} mt={1}>
          {timesheets.map((timesheet) => (
            <DtTimeEntryPreviewTravelerInfo
              key={timesheet.id}
              timesheetId={timesheet.id}
              handleSeeMore={onSeeMore}
              traveler={timesheet.travelerName}
              jobId={timesheet.jobId}
              facility={timesheet.facility}
              timesheetStartDate={timesheet.timesheetStartDate}
              timesheetEndDate={timesheet.timesheetEndDate}
              shifts={timesheet.numberOfShifts}
              hoursPerShift={timesheet.hoursPerShift}
              scheduledHours={timesheet.scheduledHours}
            />
          ))}
        </Grid>
      )}

      {isAlertDialogOpen && selectedTimesheet && (
        <Dialog
          open={isAlertDialogOpen}
          onClose={() => setIsAlertDialogOpen(false)}
          maxWidth="md"
          fullWidth>
          <DialogTitle>Digital Timecard Timesheet</DialogTitle>
          <DtTimeEntryPreviewModalContent selectedTimesheet={selectedTimesheet} />
          <DialogActions>
            <Button onClick={() => setIsAlertDialogOpen(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default DtTimeEntryPreview;
