import { VMS_UPDATE_PAYPERIOD, VMS_UPDATE_VMSES } from "redux/actions/actionTypes";
import { VmsNameDisplayName } from "types/vms/vmsNameDisplayName";

type VmsSearch = {
  startDate: string;
  endDate: string;
  vmses: VmsNameDisplayName[];
};

const initialState: VmsSearch = {
  startDate: "",
  endDate: "",
  vmses: [],
};

type VmsAction = {
  startDate: string;
  endDate: string;
  vmses: VmsNameDisplayName[];
  type: string;
};

export function vmsReducer(state = initialState, action: VmsAction) {
  switch (action.type) {
    case VMS_UPDATE_PAYPERIOD: {
      return { ...state, startDate: action.startDate, endDate: action.endDate };
    }
    case VMS_UPDATE_VMSES: {
      return { ...state, vmses: action.vmses };
    }
    default:
      return state;
  }
}
