import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Backdrop,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
} from "@mui/material";
import TimecardDetailContent from "./TimeCardDetailContent";
import TimecardDetailBottombar from "./TimecardDetailBottomBar";
import TimecardDetailTopbar from "./TimecardDetailTopbar";
import { Scrollbars } from "components/shared/components/scrollBars";
import LinearProgress from "@mui/material/LinearProgress";
import TimesheetApi from "../../api/TimesheetApi";
import { mapNonTaxableExpenses } from "./missedShiftDeductHelper";
import { useDispatch, useStore } from "react-redux";
import { MISSED_SHIFT_CALCULATION_MADE, TIMESHEET_SELECTED } from "../../redux/actions/actionTypes";
import PayrollLocksApi from "../../api/PayrollLocksApi";
import { PayrollLockType, TimesheetStatus } from "../../commons/Enums";
import { useSnackbar } from "notistack";
import { LockPeriodStatus } from "types/payPeriod/lockPayPeriodStatus";
import { TimecardDetailUploadBox } from "./TimecardDetailUploadBox";
import { usePermissions } from "hooks";

type TimecardDetailProps = {
  jobAssignment: any;
  onNotWorkedAndNoExpenses: (...args: any[]) => void;
  onConvertExpenseTimecard: () => void;
  onTimecardFileUploaded: (...args: any[]) => void;
  onTimecardUploadDelete: (...args: any[]) => void;
  onFacilityReportFileUploaded: (...args: any[]) => void;
  onFacilityReportFilesAdded: (files: File[]) => void;
  onConfirmationPageFileUploaded: (...args: any[]) => void;
  onAdpCodeUpdated: (adpCode: string | null) => void;
  onCallOffsChange: (entries: any[]) => void;
  onTimecardEntriesChange: (...args: any[]) => void;
  onTimecardAdjustmentEntriesChange?: (...args: any[]) => void;
  onBillingEntriesChange: (...args: any[]) => void;
  onTaxableExpensesChange: (...args: any[]) => void;
  onBonusExpensesChange: (...args: any[]) => void;
  onNonTaxableExpensesChange: (...args: any[]) => void;
  onSave: (...args: any[]) => void;
  onBilling: (...args: any[]) => void;
  onReturn: (...args: any[]) => void;
  onReject: (...args: any[]) => void;
  onEditRequest: (...args: any[]) => void;
  onApprove: (...args: any[]) => void;
  onNotesRetrieved: (...args: any[]) => void;
  onNoteChange: (...args: any[]) => void;
  onNotifyTravelerChange: (...args: any[]) => void;
  onNoteSave: (...args: any[]) => void;
  onTimecardDetailFetched: (...args: any[]) => void;
  featureFlags: any[];
  onLateTimecard: (...args: any[]) => void;
  onUndoLateTimecard: (...args: any[]) => void;
  onTimecardDelete: (...args: any[]) => void;
  onSaveApproved: (...args: any[]) => void;
  firstDate: string;
  lastDate: string;
  onDidNotWorkReset?: (...args: any[]) => void;
  onNonTaxableAdjustmentsChange?: (...args: any[]) => void;
  fetchAssignments: (...args: any[]) => void;
  onMessagesRead: (...args: any[]) => void;
  onRateChangesAcknowledged: () => void;
};

const TimecardDetail = (props: TimecardDetailProps) => {
  const {
    jobAssignment,
    onNotWorkedAndNoExpenses,
    onConvertExpenseTimecard,
    onTimecardFileUploaded,
    onTimecardUploadDelete,
    onFacilityReportFileUploaded,
    onFacilityReportFilesAdded,
    onConfirmationPageFileUploaded,
    onAdpCodeUpdated,
    onTimecardEntriesChange,
    onTimecardAdjustmentEntriesChange,
    onBillingEntriesChange,
    onTaxableExpensesChange,
    onBonusExpensesChange,
    onNonTaxableExpensesChange,
    onSave,
    onBilling,
    onReturn,
    onReject,
    onEditRequest,
    onApprove,
    onNotesRetrieved,
    onNoteChange,
    onNotifyTravelerChange,
    onNoteSave,
    onTimecardDetailFetched,
    featureFlags,
    onTimecardDelete,
    onLateTimecard,
    onUndoLateTimecard,
    onSaveApproved,
    firstDate,
    lastDate,
    onDidNotWorkReset,
    onNonTaxableAdjustmentsChange,
    fetchAssignments,
    onMessagesRead,
    onRateChangesAcknowledged,
    onCallOffsChange,
  } = props;
  const dispatch = useDispatch();
  const snackbar = useSnackbar();

  const [isLoading, setIsLoading] = useState(true);
  const [draggedFiles, setDraggedFiles] = useState<FileList>();

  const [lateTimecardsReleased, setLateTimecardsReleased] = React.useState(false);
  const [previouslyLocked, setPreviouslyLocked] = useState(false);
  const store = useStore();

  const checkPayrollStatus = async () => {
    try {
      const result: LockPeriodStatus = await PayrollLocksApi.GetPayrollLockStatusByType(
        PayrollLockType.RegularTimecard
      );
      setLateTimecardsReleased(result.IsLocked);
      setPreviouslyLocked(result.PreviouslyLocked);
    } catch (e) {
      console.error(e);
    }
  };

  const getTimesheetDetails = (timesheetId: string) => {
    TimesheetApi.fetchTimecardDetail(timesheetId)
      .then((data) => {
        dispatch({
          type: TIMESHEET_SELECTED,
          patchedState: { timesheetSelected: data },
        });
        setIsLoading(false);
        onTimecardDetailFetched(
          processDetail(
            data.timecardTypes,
            data.timecardAdjustmentTypes == undefined ? [] : data.timecardAdjustmentTypes,
            data.billingTypes,
            data.taxableTypes,
            data.nonTaxableTypes,
            data.bonusTypes,
            data.scheduledHrs,
            data.numberOfShifts,
            data.nonBillableOrientationHrs,
            true,
            data.noExpenses,
            data.tenantId,
            data.isFirstWeek,
            data.isLastWeek,
            data.expenses,
            data.nonTaxableAdjustmentTypes,
            data
          )
        );
      })
      .catch(() => {
        setIsLoading(false);
        snackbar.enqueueSnackbar(
          "An error has occurred getting Timecard Information. Please try again."
        );
      });
  };

  useEffect(() => {
    setIsLoading(true);
    getTimesheetDetails(jobAssignment.id);
    checkPayrollStatus();
  }, [jobAssignment.id]);

  const handleTimecardDelete = () => {
    onTimecardDelete(jobAssignment);
  };

  const handleLateTimecard = () => {
    onLateTimecard(jobAssignment);
  };

  const handleUndoLateTimecard = () => {
    onUndoLateTimecard(jobAssignment);
  };

  const handleTravelerDidNotWork = (subStatus: any) => {
    onNotWorkedAndNoExpenses(subStatus);
  };

  const handleExpenseTimecard = () => {
    onConvertExpenseTimecard();
  };

  //--Mapping Billing Entries with Timecard Entries
  const updateBillingEntries = (assignment: any, timecardEntries: any) => {
    let billingEntries = [];
    if (assignment.billingEntries) {
      billingEntries = assignment.billingEntries.map((billEntry: any) => {
        const timecardEntryHours = getEntryValueByName(timecardEntries, billEntry.name);
        if (
          timecardEntryHours !== null /* exists */ &&
          timecardEntryHours !== billEntry.hours /* has changed */
        ) {
          return {
            ...billEntry,
            hours: timecardEntryHours,
          };
        }
        return billEntry;
      });
    }
    return billingEntries;
  };

  const handleTimecardEntriesChange = (timecardEntries: any, dtHoursChanged: any) => {
    const assignment = jobAssignment;
    const billingEntries = updateBillingEntries(assignment, timecardEntries);

    const detail = processDetail(
      timecardEntries,
      assignment.timecardAdjustmentTypes,
      billingEntries,
      assignment.taxableExpenses,
      assignment.nonTaxableExpenses,
      assignment.bonusExpenses,
      assignment.scheduledHours,
      assignment.numberOfShifts,
      assignment.nonBillableOrientationHrs,
      false,
      assignment.noExpenses,
      assignment.tenantId,
      store.getState().missedShiftCalculation.timesheetSelected.isFirstWeek,
      store.getState().missedShiftCalculation.timesheetSelected.isLastWeek,
      undefined,
      undefined,
      assignment
    );

    onTimecardEntriesChange(
      assignment,
      detail.timecardEntries,
      detail.timecardAdjustmentTypes,
      detail.billingEntries,
      detail.taxableExpenses,
      detail.nonTaxableExpenses,
      dtHoursChanged
    );
  };

  const handleTimecardAdjustmentEntriesChange = (timecardAdjustmentEntries: any) => {
    const assignment = jobAssignment;
    const billingEntries = updateBillingEntries(assignment, assignment.timecardEntries);

    const detail = processDetail(
      assignment.timecardEntries,
      timecardAdjustmentEntries,
      billingEntries,
      assignment.taxableExpenses,
      assignment.nonTaxableExpenses,
      assignment.bonusExpenses,
      assignment.scheduledHours,
      assignment.numberOfShifts,
      assignment.nonBillableOrientationHrs,
      false,
      assignment.noExpenses,
      assignment.tenantId,
      store.getState().missedShiftCalculation.timesheetSelected.isFirstWeek,
      store.getState().missedShiftCalculation.timesheetSelected.isLastWeek,
      undefined,
      undefined,
      assignment
    );

    onTimecardAdjustmentEntriesChange?.(
      assignment,
      detail.timecardEntries,
      detail.timecardAdjustmentTypes,
      detail.billingEntries,
      detail.taxableExpenses,
      detail.nonTaxableExpenses,
      detail.bonusExpenses,
      false
    );
  };

  const handleOnAddAdjustment = (name: string) => {
    const assignment = jobAssignment;
    const billingEntries = updateBillingEntries(assignment, assignment.timecardEntries);
    const newAdjustments = [...assignment.timecardAdjustmentTypes];
    const entryToAdjust = assignment.timecardEntries.find((te: any) => te.name == name);
    const countAdjustedEntries = newAdjustments.filter((te) => te.name == name).length;
    newAdjustments.push({
      ...entryToAdjust,
      isRateEditable: true,
      rate: 0,
      hours: 0,
      order: countAdjustedEntries + 1,
    });

    const detail = processDetail(
      assignment.timecardEntries,
      newAdjustments,
      billingEntries,
      assignment.taxableExpenses,
      assignment.nonTaxableExpenses,
      assignment.bonusExpenses,
      assignment.scheduledHours,
      assignment.numberOfShifts,
      assignment.nonBillableOrientationHrs,
      false,
      assignment.noExpenses,
      assignment.tenantId,
      store.getState().missedShiftCalculation.timesheetSelected.isFirstWeek,
      store.getState().missedShiftCalculation.timesheetSelected.isLastWeek,
      undefined,
      undefined,
      assignment
    );

    onTimecardAdjustmentEntriesChange?.(
      assignment,
      detail.timecardEntries,
      detail.timecardAdjustmentTypes,
      detail.billingEntries,
      detail.taxableExpenses,
      detail.nonTaxableExpenses,
      detail.bonusExpenses,
      false
    );
  };

  const handleOnRemoveAdjustment = (name: string, order: number) => {
    const assignment = jobAssignment;
    const billingEntries = updateBillingEntries(assignment, assignment.timecardEntries);
    let newAdjustments = [...assignment.timecardAdjustmentTypes];
    newAdjustments = newAdjustments.filter((na) => `${na.name}_${na.order}` != `${name}_${order}`);

    newAdjustments.forEach((newAdjustment) => {
      const onlyOneLeft = newAdjustments.filter((n) => n.name === newAdjustment.name).length === 1;

      if (onlyOneLeft) {
        newAdjustment.order = 1;
      }
    });

    const detail = processDetail(
      assignment.timecardEntries,
      newAdjustments,
      billingEntries,
      assignment.taxableExpenses,
      assignment.nonTaxableExpenses,
      assignment.bonusExpenses,
      assignment.scheduledHours,
      assignment.numberOfShifts,
      assignment.nonBillableOrientationHrs,
      false,
      assignment.noExpenses,
      assignment.tenantId,
      store.getState().missedShiftCalculation.timesheetSelected.isFirstWeek,
      store.getState().missedShiftCalculation.timesheetSelected.isLastWeek,
      undefined,
      undefined,
      assignment
    );

    onTimecardAdjustmentEntriesChange?.(
      assignment,
      detail.timecardEntries,
      detail.timecardAdjustmentTypes,
      detail.billingEntries,
      detail.taxableExpenses,
      detail.nonTaxableExpenses,
      detail.bonusExpenses,
      false
    );
  };

  const handleOnAddAdjustmentNonTaxable = (name: string) => {
    const assignment = jobAssignment;
    const newAdjustments = [...assignment.nonTaxableAdjustmentTypes];
    const entryToAdjust = assignment.nonTaxableExpenses.find((te: any) => te.name == name);

    const countAdjustedEntries = newAdjustments.filter((te) => te.name == name).length;

    newAdjustments.push({
      ...entryToAdjust,
      rate: 0,
      paid: 0,
      order: countAdjustedEntries + 1,
    });

    onNonTaxableAdjustmentsChange?.(jobAssignment, newAdjustments);
  };

  const handleOnRemoveAdjustmentNonTaxable = (name: string, order: number) => {
    const assignment = jobAssignment;
    let newAdjustments = [...assignment.nonTaxableAdjustmentTypes];
    newAdjustments = newAdjustments.filter((na) => `${na.name}_${na.order}` != `${name}_${order}`);

    newAdjustments.forEach((newAdjustment) => {
      const onlyOneLeft = newAdjustments.filter((n) => n.name === newAdjustment.name).length === 1;

      if (onlyOneLeft) {
        newAdjustment.order = 1;
      }
    });

    onNonTaxableAdjustmentsChange?.(jobAssignment, newAdjustments);
  };

  const handleNonTaxableAdjustmentChanges = (newAdjustments: any) => {
    onNonTaxableAdjustmentsChange?.(jobAssignment, newAdjustments);
  };

  const handleBillingEntriesChange = (billingEntries: any) => {
    onBillingEntriesChange(billingEntries);
  };

  const handleTaxableExpensesChange = (expenses: any) => {
    onTaxableExpensesChange(jobAssignment, expenses);
  };

  const handleBonusExpensesChange = (expenses: any) => {
    onBonusExpensesChange(jobAssignment, expenses);
  };

  const handleNonTaxableExpensesChange = (nonTaxableExpenses: any) => {
    const missedShiftDeductVisible =
      store.getState().missedShiftCalculation.missedShiftDeductVisible;
    if (missedShiftDeductVisible) {
      const detail = processDetail(
        jobAssignment.timecardEntries,
        jobAssignment.timecardAdjustmentTypes,
        jobAssignment.billingEntries,
        jobAssignment.taxableExpenses,
        nonTaxableExpenses,
        jobAssignment.bonusExpenses,
        jobAssignment.scheduledHours,
        jobAssignment.numberOfShifts,
        jobAssignment.nonBillableOrientationHrs,
        false,
        jobAssignment.noExpenses,
        jobAssignment.tenantId,
        store.getState().missedShiftCalculation.timesheetSelected.isFirstWeek,
        store.getState().missedShiftCalculation.timesheetSelected.isLastWeek,
        undefined,
        undefined,
        jobAssignment
      );

      onTimecardEntriesChange(
        jobAssignment,
        detail.timecardEntries,
        detail.timecardAdjustmentTypes,
        detail.billingEntries,
        detail.taxableExpenses,
        detail.nonTaxableExpenses,
        detail.bonusExpenses,
        false
      );
      onNonTaxableExpensesChange(jobAssignment, detail.nonTaxableExpenses);
    } else {
      onNonTaxableExpensesChange(jobAssignment, nonTaxableExpenses);
    }
  };

  const handleOnExpenseChange = (name: string, newAmount: number) => {
    const updatedExpenses = jobAssignment.nonTaxableExpenses.map((expense: any) => {
      if (expense.name === name) {
        return { ...expense, amount: newAmount };
      }
      return expense;
    });

    handleNonTaxableExpensesChange(updatedExpenses);
  };

  const handleNoteChange = (note: any) => {
    onNoteChange(jobAssignment, note);
  };

  const handleNotifyTravelerChange = (noteTypeFilter: any) => {
    onNotifyTravelerChange(jobAssignment, noteTypeFilter);
  };

  const handleSave = (value: any) => {
    onSave(value);
  };

  const handleSaveApproved = () => {
    onSaveApproved();
  };

  const handleReturn = () => {
    onReturn();
  };

  const handleApprove = () => {
    onApprove();
  };

  const handleBilling = () => {
    onBilling();
  };

  const handleNoteSave = () => {
    onNoteSave(jobAssignment);
  };

  const processDetail = (
    timecardEntries: any,
    timecardAdjustmentTypes: any[],
    billingEntries: any,
    taxableExpenses: any[],
    nonTaxableExpenses: any[],
    bonusExpenses: any[],
    scheduledHours: number,
    numberOfShifts: number,
    nonBillableOrientationHrs: any,
    isInitialLoad: boolean,
    noExpenses: any,
    tenantId: any,
    isFirstWeek: boolean,
    isLastWeek: boolean,
    expenses = [],
    nonTaxableAdjustmentTypes = [],
    timesheetDetails: any
  ) => {
    const nominator = {
      regularHours: getEntryValueByName(timecardEntries, "RegularHours"),
      overtime8: getEntryValueByName(timecardEntries, "Overtime8"),
      doubleTime: getEntryValueByName(timecardEntries, "DoubleTime"),
      callback: getEntryValueByName(timecardEntries, "CallBack"),
      holiday: getEntryValueByName(timecardEntries, "Holiday"),
      guaranteed: getEntryValueByName(timecardEntries, "Guaranteed"),
      overtime40: getEntryValueByName(timecardEntries, "Overtime40"),
      overtime48: getEntryValueByName(timecardEntries, "Overtime48"),
    };

    let newNonTaxableExpenses;
    const missedShiftDeductVisible =
      store.getState().missedShiftCalculation.missedShiftDeductVisible;
    if (missedShiftDeductVisible) {
      const nonTaxableMapping = mapNonTaxableExpenses(
        nominator,
        scheduledHours,
        numberOfShifts,
        isInitialLoad,
        nonTaxableExpenses,
        timecardAdjustmentTypes,
        isFirstWeek,
        isLastWeek
      );
      newNonTaxableExpenses = nonTaxableMapping.expensesMapped;
      dispatch({
        type: MISSED_SHIFT_CALCULATION_MADE,
        patchedState: { calculationMade: nonTaxableMapping.calculationMade },
      });
    } else {
      newNonTaxableExpenses = nonTaxableExpenses.map((expense) => {
        return mapExpense(
          nominator,
          scheduledHours,
          expense,
          tenantId,
          isFirstWeek,
          isLastWeek,
          isInitialLoad
        );
      });
    }

    return {
      timecardEntries: timecardEntries,
      originalTimecardEntries: JSON.parse(JSON.stringify(timecardEntries)),
      billingEntries: billingEntries,
      originalBillingEntries: JSON.parse(JSON.stringify(billingEntries)),
      originalTaxableExpenses: taxableExpenses,
      originalNonTaxableExpenses: nonTaxableExpenses,
      originalBonusExpenses: bonusExpenses,
      taxableExpenses: taxableExpenses.map((expense) => {
        return mapExpense(
          nominator,
          scheduledHours,
          expense,
          tenantId,
          isFirstWeek,
          isLastWeek,
          isInitialLoad
        );
      }),
      nonTaxableExpenses: newNonTaxableExpenses,
      bonusExpenses: bonusExpenses.map((expense) => {
        return mapExpense(
          nominator,
          scheduledHours,
          expense,
          tenantId,
          isFirstWeek,
          isLastWeek,
          isInitialLoad
        );
      }),
      scheduledHours: scheduledHours,
      numberOfShifts: numberOfShifts,
      nonBillableOrientationHrs: nonBillableOrientationHrs,
      noExpenses: noExpenses,
      expensesInJobAssigment: expenses,
      timecardAdjustmentTypes: timecardAdjustmentTypes,
      originalTimecardAdjustmentTypes: JSON.parse(JSON.stringify(timecardAdjustmentTypes)),
      nonTaxableAdjustmentTypes: nonTaxableAdjustmentTypes,
      originalNonTaxableAdjustmentTypes: JSON.parse(JSON.stringify(nonTaxableAdjustmentTypes)),
      isDigitalTimecardEntry: timesheetDetails.isDigitalTimecardEntry,
      missedLunchHours: timesheetDetails.missedLunchHours,
      hasToAcknowledgeNotes: timesheetDetails.hasToAcknowledgeNotes,
      hasUnreadMessages: timesheetDetails.hasUnreadMessages,
      subStatus: timesheetDetails.currentSubStatus,
      hasEditRequest: timesheetDetails.hasEditRequest,
      isExternalAgency: timesheetDetails.isExternalAgency,
      hasBonusSection: timesheetDetails.hasBonusSection,
      hasToAcknowledgeRateChange: timesheetDetails.hasToAcknowledgeRateChange,
      jobAssignmentCallOffHospital: timesheetDetails.jobAssignmentCallOffHospital,
      jobAssignmentCallOffOther: timesheetDetails.jobAssignmentCallOffOther,
      jobAssignmentCallOffPersonal: timesheetDetails.jobAssignmentCallOffPersonal,
      jobAssignmentCallOffSick: timesheetDetails.jobAssignmentCallOffSick,
      adpCode: timesheetDetails.adpCode ?? null,
      callOffEntries: timesheetDetails.callOffsTypes,
      vmsName: timesheetDetails.vmsName,
      billingSpecialists: timesheetDetails.billingSpecialists,
    };
  };

  const mapExpense = (
    nominator: any,
    scheduledHours: number,
    expense: any,
    tenantId: any,
    isFirstWeek: boolean,
    isLastWeek: boolean,
    isInitialLoad: boolean
  ) => {
    const proratedValue = getProratedValue(nominator, scheduledHours, expense, tenantId);

    const proratedSyncBroken =
      (isInitialLoad && expense.paid != null) ||
      expense.prorated == null ||
      expense.gross == null ||
      expense.proratedSyncBroken;

    let paidValue = null;
    const expensesPaidOnFirstLastPayPeriod = ["BonusPay", "Mileage", "TravelReimbursement"];

    if (isInitialLoad && expense.paid == null && expense.gross != null && proratedValue == null) {
      if (expensesPaidOnFirstLastPayPeriod.includes(expense.name) && !isFirstWeek && !isLastWeek) {
        paidValue = 0.0;
      } else {
        paidValue = expense.gross;
      }
    } else if (!proratedSyncBroken) {
      paidValue = proratedValue;
    } else {
      paidValue = expense.paid == null ? 0.0 : expense.paid;
    }

    return {
      ...expense,
      paid: paidValue,
      prorated: proratedValue,
      proratedSyncBroken: proratedSyncBroken,
    };
  };

  const getEntryValueByName = (timecardEntries: any, name: string) => {
    const index = timecardEntries.findIndex((p: any) => p.name === name);

    if (index === -1) return null;

    return parseFloat(timecardEntries[index].hours);
  };

  const getProratedValue = (nominator: any, scheduledHours: any, expense: any, tenantId: any) => {
    if (expense.gross == null) return null;

    if (expense.prorated == null) return null;

    if (scheduledHours == null) return null;

    const totalHours =
      (nominator.regularHours || 0) +
      (nominator.overtime8 || 0) +
      (nominator.overtime40 || 0) +
      (nominator.overtime48 || 0) +
      (nominator.doubleTime || 0) +
      (nominator.callback || 0) +
      (nominator.holiday || 0) +
      (nominator.guaranteed || 0);

    if (tenantId === 2) {
      if (expense.name === "PerDiem" || expense.name === "HousingStipend") {
        if (jobAssignment.isAdjustment) {
          return expense.paid;
        }
        return expense.gross;
      }
      if (
        totalHours > 0 &&
        (expense.name === "PerDiemDeduct" || expense.name === "HousingStipendDeduct")
      ) {
        return -(expense.gross - expense.gross * Math.min(1, totalHours / scheduledHours));
      }
    }

    return expense.gross * Math.min(1, totalHours / scheduledHours);
  };

  const handleAcknowledgeNotes = () => {
    getTimesheetDetails(jobAssignment.id);
  };

  const {
    canUpdateFacilityReportFile,
    canDragAndDropInDraftStatus,
    canUpdateConfirmationPageFile,
    canUpdateTimecardFileInBillingStatus,
    canAddFilesToFacilityReport,
    canUpdateTimecardFileInDraftStatus,
    canUpdateFacilityReportFileInApprovedStatus,
  } = usePermissions();

  const shouldDragAndDrop =
    jobAssignment.status === TimesheetStatus.DRAFT ? canDragAndDropInDraftStatus : true;
  const canDragAndDropTimecard =
    jobAssignment.status === TimesheetStatus.DRAFT ? canUpdateTimecardFileInDraftStatus : true;

  useEffect(() => {
    setDraggedFiles(undefined);
    if (jobAssignment.id) {
      const dropzone = document.getElementsByClassName("timecard-detail-container").item(0);

      dropzone?.addEventListener("dragover", (e) => {
        e.preventDefault();
        if (shouldDragAndDrop) {
          (dropzone as HTMLElement).style.backgroundColor = "#f0f0f0";
        }
        e.stopPropagation();
        return false;
      });

      dropzone?.addEventListener("dragleave", (e) => {
        e.stopPropagation();
        if (shouldDragAndDrop) {
          (dropzone as HTMLElement).style.backgroundColor = "#fff";
        }
        return false;
      });

      dropzone?.addEventListener("drop", (e) => {
        e.preventDefault();
        if (shouldDragAndDrop) {
          (dropzone as HTMLElement).style.backgroundColor = "#fff";
          setDraggedFiles((e as DragEvent).dataTransfer?.files);
        }
        return false;
      });

      return () => {
        dropzone?.removeEventListener("dragover", (e) => {
          e.preventDefault();
          if (shouldDragAndDrop) {
            (dropzone as HTMLElement).style.backgroundColor = "#f0f0f0";
          }
          e.stopPropagation();
          return false;
        });

        dropzone?.removeEventListener("dragleave", (e) => {
          e.stopPropagation();
          if (shouldDragAndDrop) {
            (dropzone as HTMLElement).style.backgroundColor = "#fff";
          }
          return false;
        });

        dropzone?.removeEventListener("drop", (e) => {
          e.preventDefault();
          if (shouldDragAndDrop) {
            (dropzone as HTMLElement).style.backgroundColor = "#fff";
            setDraggedFiles((e as DragEvent).dataTransfer?.files);
          }
          return false;
        });
      };
    }
  }, [jobAssignment.id, shouldDragAndDrop]);

  const onDragDropClose = () => {
    setDraggedFiles(undefined);
  };

  const Loading = ({ isOpen }: { isOpen: boolean }) => {
    return (
      <Backdrop
        sx={{
          zIndex: 1201,
          color: "#fff",
        }}
        open={isOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  return (
    <>
      {isLoading ? (
        <Loading isOpen />
      ) : (
        <>
          <Box>
            <TimecardDetailTopbar
              jobAssignment={jobAssignment}
              onConfirmationPageFileUploaded={onConfirmationPageFileUploaded}
              onAdpCodeUpdated={onAdpCodeUpdated}
              isReadOnly={false}
              displayIcons={true}
              fetchAssignments={fetchAssignments}
              onMessagesRead={onMessagesRead}
            />
          </Box>
          {(jobAssignment.saving || jobAssignment.savingNote) && (
            <>
              <LinearProgress variant="query" />
              <Loading isOpen />
            </>
          )}
          {
            <>
              <Scrollbars autoHide style={{ height: "100%" }}>
                <Box className="timecard-detail-pane">
                  <TimecardDetailContent
                    jobAssignment={jobAssignment}
                    onNoteChange={handleNoteChange}
                    onNotifyTravelerChange={handleNotifyTravelerChange}
                    onNotesRetrieved={onNotesRetrieved}
                    onTimecardFileUploaded={onTimecardFileUploaded}
                    onTimecardUploadDelete={onTimecardUploadDelete}
                    onFacilityReportFileUploaded={onFacilityReportFileUploaded}
                    onFacilityReportFilesAdded={onFacilityReportFilesAdded}
                    onAddAdjustment={handleOnAddAdjustment}
                    onRemoveAdjustment={handleOnRemoveAdjustment}
                    onTimecardEntriesChange={handleTimecardEntriesChange}
                    onTimecardAdjustmentEntriesChange={handleTimecardAdjustmentEntriesChange}
                    onBillingEntriesChange={handleBillingEntriesChange}
                    onTaxableExpensesChange={handleTaxableExpensesChange}
                    onBonusExpensesChange={handleBonusExpensesChange}
                    onNonTaxableExpensesChange={handleNonTaxableExpensesChange}
                    onTimecardDelete={handleTimecardDelete}
                    onLateTimecard={handleLateTimecard}
                    onUndoLateTimecard={handleUndoLateTimecard}
                    onTravelerDidNotWork={handleTravelerDidNotWork}
                    onExpenseTimecard={handleExpenseTimecard}
                    featureFlags={featureFlags}
                    lateTimecardsReleased={lateTimecardsReleased}
                    previouslyLocked={previouslyLocked}
                    onDidNotWorkReset={onDidNotWorkReset}
                    onAddNonTaxableAdjustment={handleOnAddAdjustmentNonTaxable}
                    onRemoveNonTaxableAdjustment={handleOnRemoveAdjustmentNonTaxable}
                    onNonTaxableAdjustmentsChange={handleNonTaxableAdjustmentChanges}
                    onAcknowledgeNotes={handleAcknowledgeNotes}
                    onHandleOnExpenseChange={handleOnExpenseChange}
                    onEditRequest={onEditRequest}
                    onRateChangesAcknowledged={onRateChangesAcknowledged}
                    onCallOffsChange={onCallOffsChange}
                  />
                </Box>
              </Scrollbars>
              {!(jobAssignment.isLate && previouslyLocked) && (
                <TimecardDetailBottombar
                  assignment={jobAssignment}
                  onNoteSave={handleNoteSave}
                  onBilling={handleBilling}
                  onApprove={handleApprove}
                  onSave={handleSave}
                  onSaveApproved={handleSaveApproved}
                  onReturn={handleReturn}
                  onReject={onReject}
                  onDeleteTimecard={handleTimecardDelete}
                  featureFlags={featureFlags}
                />
              )}
            </>
          }
          <Dialog
            open={shouldDragAndDrop && (draggedFiles?.length ?? 0) > 0}
            onClose={onDragDropClose}>
            <DialogTitle sx={{ textAlign: "center", textTransform: "capitalize" }}>
              Upload Files
            </DialogTitle>
            <DialogContent dividers>
              <DialogContentText fontSize={16} textAlign="center">
                It looks like you are attempting to upload some files. Select which type of file you
                are uploading.
              </DialogContentText>
              <Box>
                {canUpdateConfirmationPageFile && (
                  <TimecardDetailUploadBox
                    title="Confirmation Page"
                    actionType={jobAssignment.confirmationPageUploadId ? "Update" : "Upload"}
                    onAction={onConfirmationPageFileUploaded}
                    disabled={false}
                    numberOfFiles={1}
                    files={draggedFiles!}
                    onClose={onDragDropClose}
                  />
                )}

                {canDragAndDropTimecard && (
                  <TimecardDetailUploadBox
                    title="Timecard File"
                    actionType={jobAssignment.timecardUploadId ? "Update" : "Upload"}
                    onAction={onTimecardFileUploaded}
                    disabled={
                      jobAssignment.status === TimesheetStatus.FOR_REVIEW ||
                      jobAssignment.status === TimesheetStatus.APPROVED ||
                      jobAssignment.status == TimesheetStatus.NOTIMECARD ||
                      jobAssignment.isDigitalTimecardEntry ||
                      !canUpdateTimecardFileInBillingStatus
                    }
                    numberOfFiles={1}
                    files={draggedFiles!}
                    onClose={onDragDropClose}
                  />
                )}

                <TimecardDetailUploadBox
                  title="Facility Report File"
                  actionType={jobAssignment.facilityReportUploadId ? "Update" : "Upload"}
                  onAction={onFacilityReportFileUploaded}
                  disabled={
                    (jobAssignment.status === TimesheetStatus.APPROVED &&
                      !canUpdateFacilityReportFileInApprovedStatus) ||
                    jobAssignment.status == TimesheetStatus.NOTIMECARD ||
                    (jobAssignment.isDigitalTimecardEntry &&
                      jobAssignment.facilityReportUploadId) ||
                    !canUpdateFacilityReportFile
                  }
                  numberOfFiles={1}
                  files={draggedFiles!}
                  onClose={onDragDropClose}
                />

                {jobAssignment.facilityReportUploadId && canAddFilesToFacilityReport && (
                  <TimecardDetailUploadBox
                    title="Facility Report File"
                    actionType={"Add Files"}
                    onAction={onFacilityReportFilesAdded}
                    disabled={
                      (jobAssignment.status === TimesheetStatus.APPROVED &&
                        !canUpdateFacilityReportFileInApprovedStatus) ||
                      jobAssignment.status == TimesheetStatus.NOTIMECARD
                    }
                    numberOfFiles={5}
                    files={draggedFiles!}
                    onClose={onDragDropClose}
                  />
                )}
              </Box>
              <DialogActions sx={{ justifyContent: "center" }}>
                <Button
                  size="medium"
                  fullWidth
                  variant="outlined"
                  onClick={onDragDropClose}
                  sx={{ width: 200 }}>
                  Cancel
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
        </>
      )}
    </>
  );
};

export default TimecardDetail;
