import {
  VmsTableFacilityItem,
  VmsTableFacilityTravelerItem,
  VmsTableFacilityTravelerTimesheetItem,
  VmsTableItem,
} from "types/vms/vmsTableItem";

type HoursKeys = keyof Pick<
  VmsTableFacilityTravelerTimesheetItem,
  | "regularHours"
  | "overtimeHours"
  | "oncallHours"
  | "callBackHours"
  | "holidayHours"
  | "nonBillableHours"
>;

function getSummarizedHours(traveler: VmsTableFacilityTravelerItem, hoursKey: HoursKeys) {
  return traveler.timesheets.reduce((acc, timesheet) => {
    return acc + timesheet[hoursKey];
  }, 0.0);
}

function getFacilitySummarizedHours(facility: VmsTableFacilityItem, hoursKey: HoursKeys) {
  return facility.travelers.reduce((acc, traveler) => {
    const timesheetHours = getSummarizedHours(traveler, hoursKey);
    return acc + timesheetHours;
  }, 0.0);
}

function getVmsSummarizedHours(vmsProfile: VmsTableItem, hoursKey: HoursKeys) {
  return vmsProfile.facilities.reduce((acc, facility) => {
    const timesheetHours = getFacilitySummarizedHours(facility, hoursKey);
    return acc + timesheetHours;
  }, 0.0);
}

export { getSummarizedHours, getVmsSummarizedHours, getFacilitySummarizedHours };
