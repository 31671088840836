import {
  Alert,
  Box,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DtTimeEntryPreviewModalContextTableHeadCell from "./DtTimeEntryPreviewModalContextTableHeadCell";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { DtTimecard } from "types/dtTimeEntryPreview/dtTimecard";
import { useSnackbar } from "notistack";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";

type DtTimeEntryPreviewModalContextTableProps = {
  timecardTimeEntry: DtTimecard;
  candidateName: string;
  hoursPerShift: number | null;
  numberOfShifts: number | null;
  scheduledHours: number;
};

const DtTimeEntryPreviewModalContextTable = ({
  timecardTimeEntry,
  candidateName,
  hoursPerShift,
  numberOfShifts,
  scheduledHours,
}: DtTimeEntryPreviewModalContextTableProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [timesIn, setTimesIn] = useState<string[]>([]);
  const [timesOut, setTimesOut] = useState<string[]>([]);
  const [lunches, setLunches] = useState<string[]>([]);
  const [callsOff, setCallsOff] = useState<string[]>([]);
  const [callsBack, setCallsBack] = useState<string[][]>([]);
  const [onCalls, setOnCalls] = useState<string[][]>([]);

  useEffect(() => {
    if (timecardTimeEntry.timeEntry) {
      const timesInLocal: string[] = [];
      const timesOutLocal: string[] = [];
      const lunchesLocal: string[] = [];
      const callsOffLocal: string[] = [];
      const onCallsLocal: string[][] = [];
      const callsBackLocal: string[][] = [];

      timecardTimeEntry.timeEntry.week.forEach((_, index) => {
        const day = timecardTimeEntry.timeEntry?.days.find((d) => d.weekIndex === index);

        if (!day) {
          timesInLocal.push("--");
          timesOutLocal.push("--");
          lunchesLocal.push("--");
          callsOffLocal.push("--");
          onCallsLocal.push(["--", ""]);
          callsBackLocal.push(["--", ""]);
        } else {
          if (day.regularHours?.reasonForCallOff) {
            callsOffLocal.push(day.regularHours.reasonForCallOff);
          } else {
            callsOffLocal.push("--");
          }

          if (day.regularHours?.timeIn) {
            timesInLocal.push(day.regularHours.timeIn);
          } else {
            timesInLocal.push("--");
          }

          if (day.regularHours?.timeOut) {
            timesOutLocal.push(day.regularHours.timeOut);
          } else {
            timesOutLocal.push("--");
          }

          if (day.regularHours?.lunchTime) {
            lunchesLocal.push(day.regularHours.lunchTime);
          } else {
            lunchesLocal.push("--");
          }

          if (day.onCallHours) {
            const onCallHours = day.onCallHours.calls.map((call) => {
              return `${call.timeIn ?? ""} - ${call.timeOut ?? ""}`;
            });
            onCallsLocal.push(onCallHours);
          } else {
            onCallsLocal.push(["--", ""]);
          }

          if (day.callBackHours) {
            const callbackHours = day.callBackHours.calls.map((call) => {
              return `${call.timeIn ?? ""} - ${call.timeOut ?? ""}`;
            });
            callsBackLocal.push(callbackHours);
          } else {
            callsBackLocal.push(["--", ""]);
          }
        }
      });

      setTimesIn(timesInLocal);
      setTimesOut(timesOutLocal);
      setLunches(lunchesLocal);
      setOnCalls(onCallsLocal);
      setCallsBack(callsBackLocal);
      setCallsOff(callsOffLocal);
    }
  }, [timecardTimeEntry]);

  const signatureLabel = timecardTimeEntry?.signatureRequestId
    ? "Signature ID: " + timecardTimeEntry.signatureRequestId
    : "No Signature ID";
  const showCopySignatureId = !!timecardTimeEntry?.signatureRequestId;
  const copySignatureId = () => {
    navigator.clipboard.writeText(timecardTimeEntry?.signatureRequestId ?? "");
    enqueueSnackbar("Signature ID copied to clipboard", {
      variant: "success",
      autoHideDuration: 2000,
    });
  };

  const parseTime = (time: string) => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  };

  const totalRegularHours =
    timecardTimeEntry.timeEntry?.days.reduce((acc, day) => {
      const timeIn = day.regularHours?.timeIn;
      const timeOut = day.regularHours?.timeOut;
      const lunchTime = day.regularHours?.lunchTime;

      // Skip this day if there's no timeIn or timeOut
      if (!timeIn || !timeOut) {
        return acc;
      }

      const minutesIn = parseTime(timeIn);
      const minutesOut = parseTime(timeOut);
      const lunchMinutes = lunchTime ? parseTime(lunchTime) : 0;

      // Handle overnight shifts (e.g., 23:00 to 07:00)
      const minutesWorked =
        minutesOut >= minutesIn
          ? minutesOut - minutesIn // Same day
          : 1440 - minutesIn + minutesOut; // Overnight

      const netMinutes = minutesWorked - lunchMinutes;

      const totalMinutes = acc + netMinutes;

      return totalMinutes;
    }, 0) ?? 0;

  // Convert total minutes to HH:mm format
  const formatTime = (totalMinutes: number) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
  };

  const totalRegularHoursFormatted = formatTime(totalRegularHours);

  const totalOnCallHours =
    timecardTimeEntry.timeEntry?.days.reduce((acc, day) => {
      const onCallHours = day.onCallHours?.calls.reduce((acc, call) => {
        const timeIn = call.timeIn;
        const timeOut = call.timeOut;

        if (!timeIn || !timeOut) {
          return acc;
        }

        const minutesIn = parseTime(timeIn);
        const minutesOut = parseTime(timeOut);

        const minutesWorked = minutesOut - minutesIn;

        return acc + minutesWorked;
      }, 0);

      return acc + (onCallHours ?? 0);
    }, 0) ?? 0;

  const totalOnCallHoursFormatted = formatTime(totalOnCallHours);

  const totalCallBackHours =
    timecardTimeEntry.timeEntry?.days.reduce((acc, day) => {
      const callBackHours = day.callBackHours?.calls.reduce((acc, call) => {
        const timeIn = call.timeIn;
        const timeOut = call.timeOut;

        if (!timeIn || !timeOut) {
          return acc;
        }

        const minutesIn = parseTime(timeIn);
        const minutesOut = parseTime(timeOut);

        const minutesWorked = minutesOut - minutesIn;

        return acc + minutesWorked;
      }, 0);

      return acc + (callBackHours ?? 0);
    }, 0) ?? 0;

  const totalCallBackHoursFormatted = formatTime(totalCallBackHours);

  return (
    <>
      <Typography variant="h6">{candidateName}</Typography>
      <Box display={"flex"} flexDirection={"row"} mb={1} gap={1}>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          <span style={{ verticalAlign: "sub" }}>
            <QueryBuilderIcon fontSize="medium" />
          </span>{" "}
          Number of Shifts: {numberOfShifts ?? "--"}
        </Typography>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          <span style={{ verticalAlign: "sub" }}>
            <QueryBuilderIcon fontSize="medium" />
          </span>{" "}
          Hours per Shift: {hoursPerShift ?? "--"}
        </Typography>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          <span style={{ verticalAlign: "sub" }}>
            <QueryBuilderIcon fontSize="medium" />
          </span>{" "}
          Scheduled Hours: {scheduledHours}
        </Typography>
      </Box>
      <Box display={"flex"} justifyContent={"space-between"} mb={1}>
        <Chip label={timecardTimeEntry.status} color="primary" size="small" />
        <Chip
          label={signatureLabel}
          color="primary"
          size="small"
          variant="outlined"
          sx={{ ml: 1 }}
          deleteIcon={
            showCopySignatureId ? (
              <Tooltip title="Copy" arrow placement="top" enterDelay={0}>
                <ContentCopyIcon fontSize="small" />
              </Tooltip>
            ) : undefined
          }
          onDelete={showCopySignatureId ? copySignatureId : undefined}
        />
      </Box>
      {!timecardTimeEntry.timeEntry && (
        <Alert sx={{ mt: 4 }} severity="info">
          No time entry available
        </Alert>
      )}
      {timecardTimeEntry.timeEntry && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {timecardTimeEntry.timeEntry.week.map((date) => (
                  <DtTimeEntryPreviewModalContextTableHeadCell key={date} date={date} />
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ textAlign: "center" }}>
                <TableCell>Time In</TableCell>
                {timesIn.map((timeIn, index) => (
                  <TableCell key={index} sx={{ textAlign: "center" }}>
                    {timeIn}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow sx={{ textAlign: "center" }}>
                <TableCell>Time Out</TableCell>
                {timesOut.map((timeOut, index) => (
                  <TableCell key={index} sx={{ textAlign: "center" }}>
                    {timeOut}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow sx={{ textAlign: "center" }}>
                <TableCell>Lunch</TableCell>
                {lunches.map((lunch, index) => (
                  <TableCell key={index} sx={{ textAlign: "center" }}>
                    {lunch}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow sx={{ textAlign: "center" }}>
                <TableCell>On Call</TableCell>
                {onCalls.map((onCall, index) => (
                  <TableCell key={index} sx={{ textAlign: "center" }}>
                    <ul style={{ listStyleType: "none", padding: 0 }}>
                      <li>{onCall[0]}</li>
                      <li>{onCall[1]}</li>
                    </ul>
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell>Callback</TableCell>
                {callsBack.map((callBack, index) => (
                  <TableCell key={index} sx={{ textAlign: "center" }}>
                    <ul style={{ listStyleType: "none", padding: 0 }}>
                      <li>{callBack[0]}</li>
                      <li>{callBack[1]}</li>
                    </ul>
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell>Call Off</TableCell>
                {callsOff.map((callOff, index) => (
                  <TableCell key={index} sx={{ textAlign: "center" }}>
                    {callOff}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} sx={{ fontWeight: 500, pt: 4 }}>
                  Totals
                </TableCell>
                <TableCell colSpan={2} sx={{ textAlign: "center", pt: 4 }}>
                  Regular: {totalRegularHoursFormatted}
                </TableCell>
                <TableCell colSpan={2} sx={{ textAlign: "center", pt: 4 }}>
                  On Call: {totalOnCallHoursFormatted}
                </TableCell>
                <TableCell colSpan={2} sx={{ textAlign: "center", pt: 4 }}>
                  Callback: {totalCallBackHoursFormatted}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default DtTimeEntryPreviewModalContextTable;
