import React from "react";
import { FormControl, InputLabel } from "@mui/material";
import MultiSelectChip from "components/history/MultiSelectChip";
import { useDispatch, useSelector } from "react-redux";
import { VMS_UPDATE_VMSES } from "redux/actions/actionTypes";
import { useSnackbar } from "notistack";
import { VmsNameDisplayName } from "types/vms/vmsNameDisplayName";

type VmsesSearchProps = {
  vmsesOptions: VmsNameDisplayName[];
};

const VmsesSearch = ({ vmsesOptions }: VmsesSearchProps) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const selectedVms = useSelector((state: RootState) => state.vmsProfile.vmses);

  const handleChange = (event: any) => {
    if (selectedVms.length < 5 || event.target.value.length < selectedVms.length) {
      const newSelectedItems = vmsesOptions.filter((t) => event.target.value.includes(t.name));

      dispatch({
        type: VMS_UPDATE_VMSES,
        vmses: newSelectedItems,
      });
    } else {
      enqueueSnackbar("You can only select up to 5 VMSes", { variant: "warning" });
    }
  };

  const handleDelete = (selectedValue: string) => {
    const newSelectedItems = selectedVms.filter((d) => d.name !== selectedValue);

    dispatch({
      type: VMS_UPDATE_VMSES,
      vmses: newSelectedItems,
    });
  };

  return (
    <FormControl variant="outlined" size="small" fullWidth>
      <InputLabel>VMS</InputLabel>
      <MultiSelectChip
        label="VMS"
        options={vmsesOptions}
        optionsSelected={selectedVms.map((x) => x.name)}
        onOptionSelected={handleChange}
        onOptionDeletedChip={handleDelete}
        optionValue="name"
        optionLabel="displayName"
      />
    </FormControl>
  );
};

export default VmsesSearch;
