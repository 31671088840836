import { VmsTableFacilityItem } from "types/vms/vmsTableItem";
import { msalApiFetch } from "../commons/authConfig";
import { VmsSetupTable } from "types/vms/vmsSetupTable";
import { ItemList } from "types/itemList";
import { VmsNameDisplayName } from "types/vms/vmsNameDisplayName";

const baseUrl = process.env.REACT_APP_API_URL;

function getVmsProfiles() {
  const url = `${baseUrl}/vms/profile`;
  return msalApiFetch(url).then((results) => {
    if (!results.ok) throw results;
    return results.json();
  });
}

function getAllVmses(): Promise<VmsNameDisplayName[]> {
  const url = `${baseUrl}/vms/getVmsNames`;

  return msalApiFetch(url, {
    method: "get",
  }).then((results) => {
    if (!results.ok) throw results;
    return results.json();
  });
}

function getFacilitiesByVmses(
  startDate: string,
  endDate: string,
  vmses: string[]
): Promise<Omit<VmsTableFacilityItem, "isLoading">[]> {
  const url = `${baseUrl}/vms/getVmsFacilitites`;

  return msalApiFetch(url, {
    body: JSON.stringify({
      startDate: startDate.substring(0, 10),
      endDate: endDate.substring(0, 10),
      vmsNames: vmses,
    }),
    method: "post",
  }).then((results) => {
    if (!results.ok) throw results;
    return results.json();
  });
}

async function getVmsSetupTable(): Promise<VmsSetupTable[]> {
  const url = `${baseUrl}/vms/getAllVms`;
  const results = await msalApiFetch(url);
  if (!results.ok) throw results;
  return results.json();
}

async function saveVmsSetupTable(vms: VmsSetupTable) {
  const url = `${baseUrl}/vms/${vms.id}`;
  const body = { ...vms, billingSpecialists: vms.billingSpecialists.map((x) => x.id) };
  const results = await msalApiFetch(url, {
    body: JSON.stringify(body),
    method: "put",
  });
  if (!results.ok) throw results;
}

async function getBillingSpecialists(): Promise<ItemList[]> {
  const url = `${baseUrl}/vms/getSpecialist`;
  const results = await msalApiFetch(url);
  if (!results.ok) throw results;
  return results.json();
}

export {
  getVmsProfiles,
  getAllVmses,
  getFacilitiesByVmses,
  getVmsSetupTable,
  saveVmsSetupTable,
  getBillingSpecialists,
};
