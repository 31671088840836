import React from "react";

import { Button } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

const ResetFiltersButton = () => {
  const handleRefreshProfileState = () => {
    window.location.reload();
  };

  return (
    <Button onClick={handleRefreshProfileState}>
      <RefreshIcon color="primary" />
      Reset filters
    </Button>
  );
};

export default ResetFiltersButton;
