import { Box, Chip } from "@mui/material";
import React from "react";

type VmsProfileSummarizedHoursProps = {
  regularHours: number;
  overtimeHours: number;
  onCallHours: number;
  callBackHours: number;
  holidayHours: number;
  nonBillableOrientationHours: number;
};

const VmsProfileSummarizedHours = ({
  regularHours,
  overtimeHours,
  onCallHours,
  callBackHours,
  holidayHours,
  nonBillableOrientationHours,
}: VmsProfileSummarizedHoursProps) => {
  const regular = "Reg Hours: " + regularHours.toFixed(2);
  const overtime = "OT/DT Hours: " + overtimeHours.toFixed(2);
  const onCall = "On Call: " + onCallHours.toFixed(2);
  const callBack = "Call Back: " + callBackHours.toFixed(2);
  const holiday = "Holiday: " + holidayHours.toFixed(2);
  const nonBillableOrientation =
    "Non-Billable Orientation: " + nonBillableOrientationHours.toFixed(2);

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <Chip label={regular} size="small"></Chip>
      <Chip label={overtime} size="small"></Chip>
      <Chip label={onCall} size="small"></Chip>
      <Chip label={callBack} size="small"></Chip>
      <Chip label={holiday} size="small"></Chip>
      <Chip label={nonBillableOrientation} size="small"></Chip>
    </Box>
  );
};

export default VmsProfileSummarizedHours;
