import React from "react";
import { Grid, Card, CardHeader, CardContent, CardActions, Skeleton } from "@mui/material";

const DtTimeEntrySkeletonSearch = () => {
  return (
    <Grid container spacing={2} mt={1}>
      {[...Array(4)].map((_, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card sx={{ maxWidth: 345, m: 2 }}>
            <CardHeader
              avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
              title={
                <Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />
              }
              subheader={<Skeleton animation="wave" height={10} width="60%" />}
            />
            <CardContent sx={{ py: 0.5 }}>
              <>
                <Skeleton animation="wave" height={20} width="80%" />
                <Skeleton animation="wave" height={20} width="80%" />
                <Skeleton animation="wave" height={20} width="80%" />
                <Skeleton animation="wave" height={20} width="80%" />
                <Skeleton animation="wave" height={20} width="80%" />
              </>
            </CardContent>
            <CardActions>
              <Skeleton animation="wave" height={20} width="100%" />
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default DtTimeEntrySkeletonSearch;
