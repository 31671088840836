import { msalApiFetch } from "commons/authConfig";
import { DtTimeEntryPreviewSearchResult } from "types/dtTimeEntryPreview/DtTimeEntryPreviewSearchResult";

const baseUrl = process.env.REACT_APP_API_URL;

const getTimesheetsToPreview = (
  traveler: string[],
  includeNextPayPeriod: boolean
): Promise<DtTimeEntryPreviewSearchResult[]> => {
  return msalApiFetch(`${baseUrl}/timesheets/search-time-entry-preview`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ traveler, includeNextPayPeriod }),
  }).then((result) => {
    if (!result.ok) throw result;
    return result.json();
  });
};

const getTimeEntry = (timesheet: DtTimeEntryPreviewSearchResult): Promise<any> => {
  return msalApiFetch(`${baseUrl}/timesheets/${timesheet.id}/time-entry-preview`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(timesheet),
  }).then((result) => {
    if (!result.ok) throw result;
    return result.json();
  });
};

export { getTimesheetsToPreview, getTimeEntry };
