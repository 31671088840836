import React, { useState } from "react";
import {
  CircularProgress,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { VmsTableFacilityItem } from "types/vms/vmsTableItem";
import VmsProfileTableRowTraveler from "./VmsProfileTableRowTraveler";
import { Box } from "@mui/system";
import VmsProfileSummarizedHours from "./VmsProfileSummarizedHours";
import { getFacilitySummarizedHours } from "./utils";

type VmsProfileTableRowFacilityProps = {
  facility: VmsTableFacilityItem;
};

const VmsProfileTableRowFacility = ({ facility }: VmsProfileTableRowFacilityProps) => {
  const [open, setOpen] = useState(false);

  const regularHours = getFacilitySummarizedHours(facility, "regularHours");

  const overtimeHours = getFacilitySummarizedHours(facility, "overtimeHours");

  const onCallHours = getFacilitySummarizedHours(facility, "oncallHours");

  const callBackHours = getFacilitySummarizedHours(facility, "callBackHours");

  const holidayHours = getFacilitySummarizedHours(facility, "holidayHours");

  const nonBillableOrientationHours = getFacilitySummarizedHours(facility, "nonBillableHours");

  return (
    <>
      <TableRow>
        <TableCell colSpan={2} onClick={() => setOpen(!open)} sx={{ cursor: "pointer", pl: 4 }}>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
          </IconButton>
          {facility.facilityName}
          <VmsProfileSummarizedHours
            regularHours={regularHours}
            overtimeHours={overtimeHours}
            onCallHours={onCallHours}
            callBackHours={callBackHours}
            holidayHours={holidayHours}
            nonBillableOrientationHours={nonBillableOrientationHours}
          />
        </TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ pl: 0, py: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {facility.isLoading ? (
              <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                <CircularProgress />
              </Box>
            ) : (
              <TableContainer>
                <Table>
                  <TableBody>
                    {facility.travelers.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={12} sx={{ pl: 8 }}>
                          No travelers found
                        </TableCell>
                      </TableRow>
                    )}
                    {facility.travelers.map((traveler, index) => (
                      <VmsProfileTableRowTraveler
                        key={traveler.travelerId + index}
                        traveler={traveler}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default VmsProfileTableRowFacility;
