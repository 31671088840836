import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import SwitchPayPeriodApi from "../../../api/SwitchPayPeriodApi";
import { downloadTriagePlusReport, getReportFileName } from "api/TriagePlusReportApi";
import { getWeekDays } from "../../shared/functions/PayPeriodDateHelper";
import moment from "moment";
import PayPeriodSelector from "../../shared/components/PayPeriodSelector";
import { downloadBlob } from "commons/utils";
import { useSnackbar } from "notistack";
import { downloadExchangeTriagePlusReport, getExchangeReportFileName } from "api/ExchangeReportApi";

type TriagePlusReportDownloadDialogProps = {
  onClose: () => void;
  open: boolean;
  toggleLoader: (a: boolean) => void;
  report: "triagePlus" | "exchange";
};

const formatDate = "MM/DD/YYYY";
const TriagePlusReportDownloadDialog = ({
  open,
  onClose,
  toggleLoader,
  report,
}: TriagePlusReportDownloadDialogProps) => {
  const [selectedDays, setSelectedDays] = useState<Date[]>([]);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();

  const snackbar = useSnackbar();

  const loadPeriods = async () => {
    try {
      const response = await SwitchPayPeriodApi.GetActivePayPeriod();
      const periodStartDate = moment(new Date(response.periodStartDate)).startOf("day");
      const weekDays = getWeekDays(periodStartDate.format(formatDate));
      setSelectedDays(weekDays);
      setStartDate(weekDays[0]);
      setEndDate(weekDays[6]);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    loadPeriods();
  }, []);

  const handleClose = () => {
    onClose();
  };

  const handleDatesChange = (startDate: Date, endDate: Date) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleDownload = async () => {
    toggleLoader(true);
    let fileName = "";
    try {
      if (report === "triagePlus") {
        const response = await getReportFileName(moment(startDate).format(formatDate));
        fileName = response.fileName;
      } else if (report === "exchange") {
        const response = await getExchangeReportFileName(moment(startDate).format(formatDate));
        fileName = response.fileName;
      }
    } catch (e) {
      snackbar.enqueueSnackbar("No period found.");
    }

    try {
      if (report === "triagePlus") {
        const response = await downloadTriagePlusReport(
          moment(startDate).format(formatDate),
          moment(endDate).format(formatDate)
        );
        await downloadBlob(new Blob([response]), fileName);
      } else if (report === "exchange") {
        const response = await downloadExchangeTriagePlusReport(
          moment(startDate).format(formatDate),
          moment(endDate).format(formatDate)
        );
        await downloadBlob(response, fileName);
      }
    } catch (error: any) {
      if (error?.status === 404) {
        snackbar.enqueueSnackbar("No timecards found for this period.");
      }
    }

    toggleLoader(false);
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className={"new-timecard-dialog"}>
        <DialogTitle id="form-dialog-title">Select Pay Period</DialogTitle>
        <DialogContent>
          <Box justifyContent={"center"} display={"flex"}>
            <PayPeriodSelector
              selectedDays={selectedDays}
              onChange={handleDatesChange}
              disabledCurrentPayPeriod={false}
              showCalendar={true}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDownload} color="primary">
            Download
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TriagePlusReportDownloadDialog;
