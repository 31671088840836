import React from "react";
import { MTableToolbar } from "material-table";

import FailedNetsuiteToggle from "./ProfileToolbar/FailedNetsuiteToggle";
import ReportsDropdownMenu from "./ReportMenuDropdown/ReportsDropdownMenu";
import ResetFiltersButton from "./ProfileToolbar/ResetFiltersButton";
import { Box } from "@mui/system";

type IProfileToolbarProps = {
  isLoading: boolean;
  onClickUltiproReportLink: () => void;
  onClickLateUltiproReportLink: () => void;
  onClickPayrollReportLink: () => void;
  onClickLatePayrollReportLink: () => void;
  onClickBillingReportLink: () => void;
  onClickLateBillingReportLink: () => void;
  onClickAdjustementBillingReportLink: () => void;
  onClickTimecardImportLink: () => void;
  onClickExchangeImportLink: () => void;
};

const ProfileToolbar = (props: IProfileToolbarProps) => {
  const boxSX = { display: "flex", justifyContent: "flex-end", alignItems: "center" };

  return (
    <Box sx={boxSX}>
      {/* Failed Netsuite toggle */}
      <FailedNetsuiteToggle />

      {/* Search field */}
      <MTableToolbar {...props} />

      {/* Reset Filters button */}
      <ResetFiltersButton />

      {/* Reports dropdown menu */}
      <ReportsDropdownMenu
        isLoading={props.isLoading}
        onClickUltiproReportLink={() => props.onClickUltiproReportLink()}
        onClickLateUltiproReportLink={() => props.onClickLateUltiproReportLink()}
        onClickPayrollReportLink={() => props.onClickPayrollReportLink()}
        onClickLatePayrollReportLink={() => props.onClickLatePayrollReportLink()}
        onClickBillingReportLink={() => props.onClickBillingReportLink()}
        onClickLateBillingReportLink={() => props.onClickLateBillingReportLink()}
        onClickAdjustmentBillingReportLink={() => props.onClickAdjustementBillingReportLink()}
        onClickTimecardImportLink={() => props.onClickTimecardImportLink()}
        onClickExchangeImportLink={() => props.onClickExchangeImportLink()}
      />
    </Box>
  );
};

export default ProfileToolbar;
