import React from "react";
import moment from "moment";
import { Avatar, List, Typography } from "@mui/material";
import { JobAssignmentNote } from "components/notes/JobAssignmentNote";
import ConfirmationPageFileIcon from "./ConfirmationPageFileIcon";
import CanRenderChildren from "components/shared/functions/CanRenderChildren";
import { WorkWeek } from "commons/Enums";
import { Box } from "@mui/system";
import { JobAssignmentSearchHistory } from "types/history/JobAssignmentCardHistory";
import { Note } from "types/notes";
import TimecardDetailTopbarCallOffs from "components/timecards/TimecardDetailTopbarCallOffs";
import UserNotifications from "components/timecards/UserNotifications";

type JobAssignmentHeaderProps = {
  jobAssignment: JobAssignmentSearchHistory;
  hasUnreadMessages: boolean;
  onMessagesRead: () => void;
};

const JobAssignmentHeader = (props: JobAssignmentHeaderProps) => {
  const { jobAssignment, hasUnreadMessages, onMessagesRead } = props;
  const startDate = moment(jobAssignment.jobAssignmentStartDate).format("MM/DD/YYYY");
  const endDate = moment(jobAssignment.jobAssignmentEndDate).format("MM/DD/YYYY");

  const hoursPerShift = (
    jobAssignment.scheduledHours && jobAssignment.numberOfShifts
      ? (parseFloat(jobAssignment.scheduledHours) / jobAssignment.numberOfShifts).toFixed(2)
      : "--"
  ) as string;

  const lastTimesheetId = jobAssignment.jobAssignmentTimecardsHistory[0].timesheetId;

  const billingSpecialists =
    jobAssignment.billingSpecialists.length > 0
      ? jobAssignment.billingSpecialists.join(", ")
      : "No Billing Specialists";

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }} mx={5} mt={3} mb={2}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}>
        <Avatar
          sx={{
            width: "70px",
            height: "70px",
            backgroundColor: "#50478b",
            color: "white",
            fontSize: "2em",
            marginRight: "10px",
          }}>
          {jobAssignment.candidateFullName
            .split(" ")
            .map((n) => n[0])
            .join("")}
        </Avatar>
        <Box pl={1}>
          <Typography fontSize={20} fontWeight="bold">
            {jobAssignment.candidateFullName}

            <UserNotifications
              candidateFullName={jobAssignment.candidateFullName}
              hasUnreadMessages={hasUnreadMessages}
              onMessagesRead={onMessagesRead}
              timesheetId={lastTimesheetId}
              isReadOnly={true}
            />
          </Typography>
          <Typography fontSize={12} color="#999" textTransform="uppercase">
            Id: {jobAssignment.candidateSSN}
            {" - Assignment Period: "}
            {startDate + " - " + endDate}
          </Typography>
          <Typography fontSize={12} color="#999" textTransform="uppercase">
            {jobAssignment.clientName + " - " + jobAssignment.clientLocation}
          </Typography>
          <Typography fontSize={12} color="#999" textTransform="uppercase">
            {"Number of Shifts: " +
              (jobAssignment.numberOfShifts ?? "--") +
              ", Hours per Shift: " +
              hoursPerShift +
              ", Scheduled Hours: " +
              (jobAssignment.scheduledHours ?? "--")}
          </Typography>

          <Typography fontSize={12} color="#999" textTransform="uppercase">
            Billing specialists: {billingSpecialists}
          </Typography>

          <TimecardDetailTopbarCallOffs
            hospitalCallOffs={jobAssignment.jobAssignmentCallOffHospital}
            otherCallOffs={jobAssignment.jobAssignmentCallOffOther}
            sickCallOffs={jobAssignment.jobAssignmentCallOffSick}
            personalCallOffs={
              jobAssignment.jobAssignmentCallOffPersonal
            }></TimecardDetailTopbarCallOffs>

          {jobAssignment.workWeek !== null && jobAssignment.workWeek !== 0 && (
            <Typography fontSize={12} color="#999" textTransform="uppercase">
              <span style={{ background: "#ffeb3b" }}>
                Alternate work week starting: {WorkWeek[jobAssignment.workWeek as never]}
              </span>
            </Typography>
          )}
          <CanRenderChildren permissionName="canSeeConfirmationPageFile">
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <Typography fontSize={14}>Confirmation Page:</Typography>
              <ConfirmationPageFileIcon
                confirmationPageUploadId={jobAssignment.confirmationPageUploadId}
              />
            </Box>
          </CanRenderChildren>
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" ml={6}>
        <Typography fontSize={15} fontWeight="bold">
          Job Assignment Notes
        </Typography>
        {jobAssignment?.jobAssignmentNotes.length > 0 ? (
          <Box
            sx={{
              display: "flex",
              overflowY: "auto",
              maxHeight: "100px",
              flexDirection: "column",
              padding: "10px",
              marginBottom: "15px",
            }}>
            <List style={{ width: "100%", resize: "none" }}>
              {jobAssignment?.jobAssignmentNotes.map((jobAssignmentNote: Note) => (
                <JobAssignmentNote key={jobAssignmentNote.noteId} note={jobAssignmentNote} />
              ))}
            </List>
          </Box>
        ) : (
          <Typography>No notes have been added</Typography>
        )}
      </Box>
    </Box>
  );
};

export default JobAssignmentHeader;
