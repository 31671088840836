import React, { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Button, Menu, MenuItem } from "@mui/material";
import PayrollLocksApi from "api/PayrollLocksApi";
import CanRenderChildren from "../functions/CanRenderChildren";
import { PayrollLockType } from "commons/Enums";
import moment from "moment";
import { LockPeriodStatus } from "types/payPeriod/lockPayPeriodStatus";

type TimercardAddButtonProps = {
  viewingSalesRep: boolean;
  openCreateTimecardDialog: () => void;
  openCreateTimecardAdjusmentDialog: () => void;
};
const TimercardAddButton = (props: TimercardAddButtonProps) => {
  const { openCreateTimecardDialog, openCreateTimecardAdjusmentDialog, viewingSalesRep } = props;
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [enableAdjustment, setenableAdjustment] = useState(false);
  const [isSecondLock, setIsSecondLock] = useState(false);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const checkPayrollLockedByType = async () => {
    try {
      const result: LockPeriodStatus = await PayrollLocksApi.GetPayrollLockStatusByType(
        PayrollLockType.RegularTimecard
      );
      setenableAdjustment(result.IsLocked);
    } catch (e) {
      console.error(e);
    }
  };

  const checkPayrollLockStatusByTye = async () => {
    try {
      const result = await PayrollLocksApi.GetPayrollLockStatusByType(
        PayrollLockType.LateAndAdjustmentTimecard
      );
      setIsSecondLock(result.IsLocked);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    checkPayrollLockedByType();
    checkPayrollLockStatusByTye();
  }, []);

  return (
    <>
      <Button onClick={(e) => openMenu(e)}>
        Add
        <AddIcon />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}>
        <MenuItem
          disabled={enableAdjustment}
          onClick={() => {
            openCreateTimecardDialog();
            handleMenuClose();
          }}>
          Add traveler
        </MenuItem>
        <CanRenderChildren permissionName="canAdjustCurrentTimecard">
          <MenuItem
            disabled={!enableAdjustment || viewingSalesRep || isSecondLock}
            onClick={() => {
              openCreateTimecardAdjusmentDialog();
              handleMenuClose();
            }}>
            Add adjustment
          </MenuItem>
        </CanRenderChildren>
      </Menu>
    </>
  );
};

export default TimercardAddButton;
