import React, { Fragment } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import NumberFormat from "react-number-format";
import BillingCommons from "../billing/BillingCommons";
import usePermissions from "../../hooks/usePermissions";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import NonTaxableExpensesTableRow from "components/timecards/NonTaxableExpensesTableRow";
import CanRenderChildren from "components/shared/functions/CanRenderChildren";
/**
 * @param {{expenses:Array, onChange: Function, isFirstWeek:boolean,isLastWeek:boolean isNonTaxable:boolean, isReadOnly:boolean, onAddAdjustment: Function, onRemoveAdjustment: Function, nonTaxableAdjustments: Array, onNonTaxableAdjustmentEntriesChange:Function}} props
 */
function NonTaxableExpenses(props) {
  const {
    expenses,
    onChange,
    isFirstWeek,
    isLastWeek,
    isReadOnly,
    onAddAdjustment,
    onRemoveAdjustment,
    nonTaxableAdjustments = [],
    onNonTaxableAdjustmentEntriesChange,
    isDigitalTimecardEntry,
    handleOnExpenseChange,
  } = props;

  const { canSeeTotalAmountTaxableExpense } = usePermissions();
  const calculationResults = useSelector(
    (state) => state.missedShiftCalculation.calculationResults
  );

  if (!expenses) return "";

  const handleTotalHoursChange = (name, value) => {
    if (!isReadOnly) {
      const newExpenses = expenses.map((r) => {
        return r.name === name
          ? {
              ...r,
              totalWorkedHrs: calculationResults.scheduledHours - value,
              proratedSyncBroken: true,
            }
          : r;
      });
      onChange(newExpenses);
    }
  };

  const handlePaidChange = (name, value, rate) => {
    let newExpenses = [];
    if (isDigitalTimecardEntry && rate) {
      newExpenses = expenses.map((r) => {
        return r.name === name ? { ...r, paid: value, proratedSyncBroken: true, rate: rate } : r;
      });
    } else {
      newExpenses = expenses.map((r) => {
        return r.name === name ? { ...r, paid: value, proratedSyncBroken: true } : r;
      });
    }
    onChange && onChange(newExpenses);
  };

  return (
    <Table
      sx={{
        minWidth: 650,
        width: "100%",
      }}
      className={" timecard-hours-entry-table"}
      size="small">
      <TableHead>
        <TableRow>
          <TableCell>Type</TableCell>
          <TableCell align="right">Paid</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {expenses.map((row) => {
          return (
            <Fragment key={"Fragment" + row.name}>
              <NonTaxableExpensesTableRow
                isDigitalTimecardEntry={isDigitalTimecardEntry}
                key={row.name}
                row={row}
                isFirstWeek={isFirstWeek}
                isLastWeek={isLastWeek}
                isReadOnly={isReadOnly}
                handlePaidChange={handlePaidChange}
                handleTotalHoursChange={handleTotalHoursChange}
                onAddAdjustment={onAddAdjustment}
                onNonTaxableAdjustmentChange={onNonTaxableAdjustmentEntriesChange}
                adjustments={nonTaxableAdjustments}
                handleOnExpenseChange={handleOnExpenseChange}></NonTaxableExpensesTableRow>

              {nonTaxableAdjustments
                .filter((a) => a.name == row.name)
                .map((a, index) => (
                  <NonTaxableExpensesTableRow
                    key={`${row.name}_adjustment_${index}`}
                    row={a}
                    isFirstWeek={isFirstWeek}
                    isLastWeek={isLastWeek}
                    isReadOnly={isReadOnly}
                    onAddAdjustment={onAddAdjustment}
                    onRemoveAdjustment={onRemoveAdjustment}
                    adjustments={nonTaxableAdjustments}
                    isAdjustment={true}
                    onNonTaxableAdjustmentChange={onNonTaxableAdjustmentEntriesChange}
                    isDigitalTimecardEntry={isDigitalTimecardEntry}
                    handleOnExpenseChange={handleOnExpenseChange}
                  />
                ))}
            </Fragment>
          );
        })}

        {(isReadOnly || canSeeTotalAmountTaxableExpense) && (
          <TableRow>
            <TableCell align="right" style={{ height: "50px", fontWeight: "bold" }}>
              Total
            </TableCell>
            <TableCell className="non-taxable-table__total" align="right">
              <NumberFormat
                fixedDecimalScale={true}
                decimalScale={2}
                prefix={"$ "}
                value={BillingCommons.getExpensesTotal(expenses, nonTaxableAdjustments)}
                thousandSeparator={true}
                displayType={"text"}
                style={{ fontWeight: "bold" }}
              />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

NonTaxableExpenses.propTypes = {
  expenses: PropTypes.array,
  onChange: PropTypes.func,
  isFirstWeek: PropTypes.bool,
  isLastWeek: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isNonTaxable: PropTypes.bool,
  nonTaxableAdjustments: PropTypes.array,
  onAddAdjustment: PropTypes.func,
  onRemoveAdjustment: PropTypes.func,
  onNonTaxableAdjustmentEntriesChange: PropTypes.func,
  isDigitalTimecardEntry: PropTypes.bool,
  handleOnExpenseChange: PropTypes.func,
};

NonTaxableExpenses.defaultProps = {
  isReadOnly: false,
  nonTaxableAdjustments: [],
};

export default NonTaxableExpenses;
