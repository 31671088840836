import {
  DT_PREVIEW_FILTERS_CHANGED_INCLUDE_NEXT,
  DT_PREVIEW_FILTERS_CHANGED_TRAVELER,
} from "redux/actions/actionTypes";
import { TravelerState } from "types/traveler";

type DtTimeEntryPreviewAction = {
  travelers: TravelerState;
  includeNextPayPeriod: boolean;
  // actual action type
  type: string;
};

export type DtTimeEntryPreviewState = {
  travelers?: TravelerState;
  includeNextPayPeriod: boolean;
};

export const dtTimeEntryPreviewDefault: DtTimeEntryPreviewState = {
  travelers: undefined,
  includeNextPayPeriod: false,
};

export function dtTimeEntryPreviewReducer(
  state = dtTimeEntryPreviewDefault,
  action: DtTimeEntryPreviewAction
) {
  switch (action.type) {
    case DT_PREVIEW_FILTERS_CHANGED_TRAVELER:
      return { ...state, travelers: action.travelers };
    case DT_PREVIEW_FILTERS_CHANGED_INCLUDE_NEXT:
      return { ...state, includeNextPayPeriod: action.includeNextPayPeriod };
    default:
      return state;
  }
}
