import React from "react";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { red } from "@mui/material/colors";
import moment from "moment";

type DtTimeEntryPreviewTravelerInfoProps = {
  timesheetId: string;
  traveler: string;
  jobId: string;
  facility: string;
  timesheetStartDate: string;
  timesheetEndDate: string;
  shifts: number | null;
  hoursPerShift: number | null;
  scheduledHours: number;
  handleSeeMore: (timesheetId: string) => void;
};

const DtTimeEntryPreviewTravelerInfo = ({
  handleSeeMore,
  timesheetId,
  traveler,
  jobId,
  timesheetStartDate,
  timesheetEndDate,
  facility,
  hoursPerShift,
  scheduledHours,
  shifts,
}: DtTimeEntryPreviewTravelerInfoProps) => {
  const startDate = moment(timesheetStartDate).format("MM/DD/YYYY");
  const endDate = moment(timesheetEndDate).format("MM/DD/YYYY");

  const onSeeMore = () => {
    handleSeeMore(timesheetId);
  };

  return (
    <Grid item xs={12} sm={4} md={3} lg={2}>
      <Card>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
              {traveler
                .split(" ")
                .map((n: any) => n[0])
                .join("")}
            </Avatar>
          }
          title={traveler}
          subheader={jobId}
        />

        <CardContent sx={{ py: 0.5 }}>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            <span style={{ verticalAlign: "sub" }}>
              <CalendarMonthIcon fontSize="medium" />
            </span>{" "}
            {startDate} - {endDate}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            <span style={{ verticalAlign: "sub" }}>
              <BusinessIcon fontSize="medium" />
            </span>{" "}
            {facility}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            <span style={{ verticalAlign: "sub" }}>
              <QueryBuilderIcon fontSize="medium" />
            </span>{" "}
            Number of Shifts: {shifts ?? "--"}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            <span style={{ verticalAlign: "sub" }}>
              <QueryBuilderIcon fontSize="medium" />
            </span>{" "}
            Hours per Shift: {hoursPerShift ?? "--"}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            <span style={{ verticalAlign: "sub" }}>
              <QueryBuilderIcon fontSize="medium" />
            </span>{" "}
            Scheduled Hours: {scheduledHours}
          </Typography>
        </CardContent>
        <CardActions sx={{ justifyContent: "center" }}>
          <Button size="small" fullWidth onClick={() => onSeeMore()}>
            See More
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default DtTimeEntryPreviewTravelerInfo;
