import React, { useState } from "react";
import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { VmsTableItem } from "types/vms/vmsTableItem";
import VmsProfileTableRowFacility from "./VmsProfileTableRowFacility";
import VmsProfileSummarizedHours from "./VmsProfileSummarizedHours";
import { getVmsSummarizedHours } from "./utils";

type VmsProfileTableRowVmsProps = {
  vmsProfile: VmsTableItem;
};

const VmsProfileTableRowVms = ({ vmsProfile }: VmsProfileTableRowVmsProps) => {
  const [open, setOpen] = useState(false);

  const regularHours = getVmsSummarizedHours(vmsProfile, "regularHours");

  const overtimeHours = getVmsSummarizedHours(vmsProfile, "overtimeHours");

  const onCallHours = getVmsSummarizedHours(vmsProfile, "oncallHours");

  const callBackHours = getVmsSummarizedHours(vmsProfile, "callBackHours");

  const holidayHours = getVmsSummarizedHours(vmsProfile, "holidayHours");

  const nonBillableOrientationHours = getVmsSummarizedHours(vmsProfile, "nonBillableHours");

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell onClick={() => setOpen(!open)} sx={{ cursor: "pointer" }}>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
          </IconButton>
          {vmsProfile.vmsName}
          <VmsProfileSummarizedHours
            regularHours={regularHours}
            overtimeHours={overtimeHours}
            onCallHours={onCallHours}
            callBackHours={callBackHours}
            holidayHours={holidayHours}
            nonBillableOrientationHours={nonBillableOrientationHours}
          />
        </TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ pl: 0, py: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <TableContainer>
              <Table>
                <TableBody>
                  {vmsProfile.facilities.map((facility, index) => (
                    <VmsProfileTableRowFacility
                      key={facility.facilityId + index}
                      facility={facility}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default VmsProfileTableRowVms;
