import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Skeleton from "@mui/material/Skeleton";
import TimesheetApi from "api/TimesheetApi";
import { useSnackbar } from "notistack";
import { TimesheetSubStatus } from "commons/Enums";

type TravelerDidNotWorkWeekDialogProps = {
  open: boolean;
  onClose: () => void;
  onTravelerDidNotWork: (subStatus: TimesheetSubStatus) => void;
  onExpenseTimecard: () => void;
  timesheetId: string;
};

const TravelerDidNotWorkWeekDialog = (props: TravelerDidNotWorkWeekDialogProps) => {
  const { open, onClose, onTravelerDidNotWork, onExpenseTimecard, timesheetId } = props;
  const [loading, setLoading] = useState(true);
  const [modalBodyText, setModalBodyText] = useState("");
  const [showConvertToExpenseTimecard, setShowConvertToExpenseTimecard] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleDidNotWork = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    substatus: TimesheetSubStatus
  ) => {
    onClose();
    onTravelerDidNotWork(substatus);
  };

  const handleExpenseTimecard = () => {
    onClose();
    onExpenseTimecard();
  };

  const getTimeEntry = async (): Promise<boolean> => {
    try {
      const response = await TimesheetApi.getHasTimeEntryInDigitalTimecard(timesheetId);

      return response;
    } catch (error) {
      enqueueSnackbar("There was an error getting information from Digital Timecard");
      throw error;
    }
  };

  const getHasExpenses = async (): Promise<boolean> => {
    try {
      const response = await TimesheetApi.hasExpenses(timesheetId);

      return response;
    } catch (error) {
      enqueueSnackbar("There was an error getting information about the timesheet");
      throw error;
    }
  };

  const getAllInfo = async () => {
    try {
      const responses = await Promise.all([getTimeEntry(), getHasExpenses()]);
      const hasTimeEntry = responses[0];
      const hasExpenses = responses[1];
      setShowConvertToExpenseTimecard(false);

      if (hasTimeEntry) {
        setModalBodyText("This timecard has time entry entered on Digital Timecard. ");
      }

      if (!hasTimeEntry && hasExpenses) {
        setModalBodyText("This timecard has expenses. ");
        setShowConvertToExpenseTimecard(true);
      }

      setLoading(false);
    } catch (error) {
      onClose();
    }
  };

  useEffect(() => {
    if (open) {
      getAllInfo().catch((error) => console.log(error));
    } else {
      setLoading(true);
      setModalBodyText("");
      setShowConvertToExpenseTimecard(false);
    }
  }, [open]);

  const modalWidth = showConvertToExpenseTimecard ? "md" : "sm";

  return (
    <div>
      <Dialog
        open={open}
        maxWidth={modalWidth}
        fullWidth
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        {loading ? (
          <>
            <DialogTitle id="alert-dialog-title">
              <Skeleton width={350} />
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Skeleton />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button disabled>
                <Skeleton width={50} />
              </Button>
              <Button disabled>
                <Skeleton width={50} />
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogTitle id="alert-dialog-title">No timecard for this week</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {modalBodyText}
                Do you want to mark it as Did Not Work or Did Not Submit
                {showConvertToExpenseTimecard && " or convert to an Expense Timecard"}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={(e) => handleDidNotWork(e, TimesheetSubStatus.DidNotWork)}
                color="primary"
                autoFocus>
                Did Not Work
              </Button>
              <Button
                onClick={(e) => handleDidNotWork(e, TimesheetSubStatus.DidNotSubmit)}
                color="primary"
                autoFocus>
                Did Not Submit
              </Button>
              {showConvertToExpenseTimecard && (
                <Button onClick={handleExpenseTimecard} color="primary" autoFocus>
                  Convert to Expense Only Timecard
                </Button>
              )}
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default TravelerDidNotWorkWeekDialog;
