import React from "react";
import { Box, Typography, Tooltip, IconButton } from "@mui/material";
import { green, orange, purple, red, yellow } from "@mui/material/colors";
import { UserStatus, UserTypeName, UsersTableItem } from "types/users";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import PersonIcon from "@mui/icons-material/Person";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import CanRenderChildren from "components/shared/functions/CanRenderChildren";

const constants = {
  inactiveUserTooltip: "Registration Not Completed.",
  activeUserTooltip: "Active User.",
  missingIdentityVerificationTooltip: "Missing Identity Verification.",
  missingTermsAndConditionsTooltip: "Missing Terms and Conditions.",
  userNotFoundTooltip: "User Not Created.",
} as const;

export const bppEmailFormat = (value: unknown, row: UsersTableItem) => {
  const otherEmails = row.bppEmails.filter((x) => x !== row.email);
  if (otherEmails.length === 0) return row.email;

  return (
    <Tooltip
      title={otherEmails.map((x) => (
        <Box key={x}>{x}</Box>
      ))}>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Typography fontSize={13}>{row.email}</Typography>
        <InfoOutlinedIcon color="action" fontSize="small" sx={{ marginLeft: 1 }} />
      </Box>
    </Tooltip>
  );
};

export const dateFormat = (value: unknown) => {
  if (!value) return "N/A";
  return moment(value as string).format("MM/DD/YYYY hh:mm A");
};

function iconColor(status: UserStatus) {
  switch (status) {
    case UserStatus.Active:
      return green[500];
    case UserStatus.MissingIdentityVerification:
      return orange[500];
    case UserStatus.MissingTermsAndConditions:
      return yellow[600];
    case UserStatus.RegistrationNotCompleted:
      return purple[500];
    default:
      return red[500];
  }
}

function iconTooltip(status: UserStatus) {
  switch (status) {
    case UserStatus.Active:
      return constants.activeUserTooltip;
    case UserStatus.MissingIdentityVerification:
      return constants.missingIdentityVerificationTooltip;
    case UserStatus.MissingTermsAndConditions:
      return constants.missingTermsAndConditionsTooltip;
    case UserStatus.RegistrationNotCompleted:
      return constants.inactiveUserTooltip;
    default:
      return constants.userNotFoundTooltip;
  }
}

// eslint-disable-next-line react/display-name
export const actionsFormat =
  // eslint-disable-next-line react/display-name
  (toggleReset?: (user: UsersTableItem) => void) => (_: unknown, row: UsersTableItem) => {
    const canResetUser = !!row.externalId;
    return (
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <CanRenderChildren permissionName="canResetDtUsers">
          <Tooltip title={canResetUser ? "Reset user" : "User does not exist in DT"}>
            <span>
              <IconButton onClick={() => toggleReset?.(row)} disabled={!canResetUser}>
                <AutorenewOutlinedIcon />
              </IconButton>
            </span>
          </Tooltip>
        </CanRenderChildren>
        <Tooltip title={iconTooltip(row.status)}>
          <PersonIcon
            sx={{
              color: iconColor(row.status),
              marginLeft: 1,
            }}
          />
        </Tooltip>
      </Box>
    );
  };

// eslint-disable-next-line react/display-name
export const bppActionsFormat =
  // eslint-disable-next-line react/display-name
  (openModal?: (user: UsersTableItem) => void) => (_: unknown, row: UsersTableItem) => {
    return (
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Tooltip title="User Details">
          <IconButton onClick={() => openModal?.(row)}>
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

export const userTypeFormat = (value: unknown) => {
  const userType = value as never;
  return <Typography>{UserTypeName[userType]}</Typography>;
};
