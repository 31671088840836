import React from "react";

import { MenuItem } from "@mui/material";

type ILinkProps = {
  title: string;
  onClick: (handler: any) => void;
};

const MenuDropdownLink = (props: ILinkProps) => {
  const menuItemSX = { marginLeft: "5px" };
  return (
    <MenuItem onClick={props.onClick} sx={menuItemSX}>
      {props.title}
    </MenuItem>
  );
};

export default MenuDropdownLink;
