/* eslint-disable no-nested-ternary */
import React from "react";
import PropTypes from "prop-types";
import {
  Avatar,
  FormControl,
  Input,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import NumberFormat from "react-number-format";
import store from "redux/configureStore";
import DealsheetValueIcon from "components/timecards/DealsheetValueIcon";
import { usePermissions } from "hooks";
import { useSelector } from "react-redux";
import useTimesheetAdjustments from "hooks/useTimesheetAdjustments";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import NonTaxableMileageReimbursement from "./NonTaxableMileageReimbursement";
import TimecardRowInformation from "components/shared/components/TimecardRowInformation";
import { IconButton, Box } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const NonTaxableExpensesTableRow = ({
  row,
  isFirstWeek,
  isLastWeek,
  isReadOnly,
  handleTotalHoursChange,
  handlePaidChange,
  onAddAdjustment,
  onRemoveAdjustment,
  isAdjustment,
  adjustments,
  onNonTaxableAdjustmentChange,
  isDigitalTimecardEntry,
  handleOnExpenseChange,
}) => {
  const expensesPaidOnFirstLastPayPeriod = ["Mileage", "TravelReimbursement"];
  const {
    canOverrideWorkedHoursMissedShiftCalculation,
    canEditMileage,
    canEditPerDiemAndHousing,
    canAdjustCurrentTimecard,
    canEditDtHours,
  } = usePermissions();
  const missedShiftCalculationState = useSelector((state) => state.missedShiftCalculation);
  const calculationResults = useSelector(
    (state) => state.missedShiftCalculation.calculationResults
  );

  const {
    canAddAdjustmentLine,
    canRemoveAdjustmentLine,
    styleVisibilityAddButton,
    updateVisibilityAddButton,
  } = useTimesheetAdjustments(row, isAdjustment, adjustments);

  const missedHoursItems = () => {
    let current = 0;
    let next = 0;
    const list = [current];
    if (calculationResults.hoursPerShift === null) {
      return list;
    }
    while (current + calculationResults.hoursPerShift <= calculationResults.scheduledHours) {
      next = current + calculationResults.hoursPerShift;
      list.push(Math.ceil(next));
      current = next;
    }
    return list;
  };

  const addAdjustment = (name) => {
    onAddAdjustment(name);
  };

  const removeAdjustment = (rowData) => {
    onRemoveAdjustment(rowData.name, rowData.order);
  };

  const handleAdjustmentPaidChange = (name, order, value) => {
    const newEntries = adjustments.map((r) => {
      return r.name === name && r.order === order ? { ...r, paid: value } : r;
    });
    onNonTaxableAdjustmentChange(newEntries);
  };

  const isMileageReimbursement = row.name === "MileageReimbursement";
  const isMileageRow = row.name == "Mileage" || isMileageReimbursement;
  const isPerDiemOrHousingRow = row.name == "PerDiem" || row.name == "HousingStipend";
  const shouldDisplayDTIndicator =
    isDigitalTimecardEntry && isMileageReimbursement && row.amount !== row.originalAmount;
  const shouldDisplayRateAndValue = isMileageReimbursement && isDigitalTimecardEntry;
  const canEditAmount = canEditDtHours && shouldDisplayRateAndValue;

  return (
    <TableRow
      key={row.name}
      onMouseEnter={() =>
        canAdjustCurrentTimecard && !isReadOnly ? updateVisibilityAddButton(true) : undefined
      }
      onMouseLeave={() =>
        canAdjustCurrentTimecard && !isReadOnly ? updateVisibilityAddButton(false) : undefined
      }>
      <TableCell
        component="th"
        scope="row"
        className={`${
          (expensesPaidOnFirstLastPayPeriod.includes(row.name) && (isFirstWeek || isLastWeek)) ||
          (row.name === "MileageReimbursement" && isDigitalTimecardEntry && row.amount > 0)
            ? "timecard-expense-highlight"
            : ""
        } ${row.name === "MissedShiftDeduct" && row.paid < 0 ? "timecard-expense-highlight" : ""}`}
        style={{ paddingLeft: isAdjustment ? "20px" : "" }}>
        {canAddAdjustmentLine && !isReadOnly && (
          <IconButton
            sx={styleVisibilityAddButton}
            size="small"
            color="primary"
            onClick={() => addAdjustment(row.name)}>
            <AddIcon fontSize="small" />
          </IconButton>
        )}
        {canRemoveAdjustmentLine && !isReadOnly && (
          <IconButton size="small" color="primary" onClick={() => removeAdjustment(row)}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        )}
        <label> {row.title} </label>

        {shouldDisplayDTIndicator && (
          <TimecardRowInformation
            informationText="Digital Timecard original amount:"
            informationValue={row?.originalAmount}
          />
        )}

        {!isAdjustment && row.name == "MissedShiftDeduct" && (
          <>
            {" - Total Missed Shift Hours:  "}
            {isReadOnly ||
            row.isLocked ||
            canOverrideWorkedHoursMissedShiftCalculation === false ? (
              <NumberFormat
                fixedDecimalScale={true}
                value={calculationResults.missedHours ?? 0.0}
                readOnly={true}
                displayType={"text"}
              />
            ) : (
              <FormControl size="small">
                <Select
                  id="missedHours"
                  name="missedHours"
                  value={calculationResults.missedHours < 0 ? 0 : calculationResults.missedHours}
                  onChange={(e) => {
                    handleTotalHoursChange(row.name, e.target.value);
                  }}>
                  {missedHoursItems().map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </>
        )}
      </TableCell>

      <TableCell align="right">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
          }}>
          {!isAdjustment &&
            missedShiftCalculationState.calculationMade === false &&
            row.name == "MissedShiftDeduct" && (
              <Tooltip title={"Missing data for calculation"} placement="top">
                <Avatar
                  id={"missedShiftDataMissing"}
                  style={{
                    backgroundColor: "red",
                    display: "inline-flex",
                    marginRight: "5px",
                    verticalAlign: "bottom",
                  }}
                  sx={{
                    width: 24,
                    height: 24,
                  }}>
                  <i className="material-icons-outlined">error_outline</i>
                </Avatar>
              </Tooltip>
            )}
          {!row.isLocked &&
          !isReadOnly &&
          (isAdjustment ||
            (isMileageRow && canEditMileage) ||
            (isPerDiemOrHousingRow &&
              canEditPerDiemAndHousing &&
              store.getState().missedShiftCalculation.timesheetSelected.isAdjustment)) ? (
            shouldDisplayRateAndValue ? (
              <NonTaxableMileageReimbursement
                row={row}
                handlePaidChange={handlePaidChange}
                canEditAmount={canEditAmount}
                handleOnExpenseChange={handleOnExpenseChange}
              />
            ) : (
              <NumberFormat
                customInput={Input}
                fixedDecimalScale={2}
                decimalScale={2}
                value={row.paid}
                onValueChange={(value) => {
                  isAdjustment
                    ? handleAdjustmentPaidChange(row.name, row.order, value.floatValue)
                    : handlePaidChange(row.name, value.floatValue);
                }}
                thousandSeparator={true}
                prefix={"$"}
                onClick={(e) => {
                  e.target.select();
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    isAdjustment
                      ? handleAdjustmentPaidChange(row.name, row.order, 0)
                      : handlePaidChange(row.name, 0);
                  }
                }}
                defaultValue={0}
                allowEmptyFormatting={false}
              />
            )
          ) : shouldDisplayRateAndValue ? (
            <NonTaxableMileageReimbursement
              row={row}
              handlePaidChange={handlePaidChange}
              isRateReadOnly={true}
            />
          ) : (
            <NumberFormat
              fixedDecimalScale={true}
              decimalScale={2}
              value={row.paid}
              prefix={"$"}
              thousandSeparator={true}
              readOnly={true}
              displayType={"text"}
            />
          )}
          {!isAdjustment &&
            calculationResults.deduct != null &&
            calculationResults.deduct < 0 &&
            row.name == "MissedShiftDeduct" && (
              <Tooltip
                sx={{ maxWidth: "none" }}
                placement={"left"}
                title={
                  <>
                    <strong style={{ fontSize: "0.8rem" }}>Missed shift deduct calculation</strong>
                    <p>
                      <strong>Formula = </strong>missed shifts x hours per shift x calculated hourly
                      rate
                    </p>
                    <pre>
                      {calculationResults.missedShifts} * {calculationResults.hoursPerShift} *{" "}
                      {calculationResults.hourlyRate.toFixed(3)} ={" "}
                      {(calculationResults.deduct * -1).toFixed(2)}
                    </pre>
                    <p>
                      <strong>Calculated hourly rate = </strong>(Per Diem + Housing Stipend) /
                      Scheduled Hours
                    </p>
                    <pre>
                      ({calculationResults.perDiemGross} + {calculationResults.housingStipendGross})
                      / {calculationResults.scheduledHours} ={" "}
                      {calculationResults.hourlyRate.toFixed(3)}
                    </pre>
                    <p>
                      <strong>Hours per shift = </strong>scheduled hours / number of shifts
                    </p>
                    <pre>
                      {calculationResults.scheduledHours} / {calculationResults.numberOfShifts} ={" "}
                      {calculationResults.hoursPerShift}
                    </pre>
                    <p>
                      <strong>Missed shifts (rounded down) = </strong>(scheduled hours - worked
                      hours) / hours per shift
                    </p>
                    <pre>
                      ({calculationResults.scheduledHours} - {calculationResults.workedHours}) /{" "}
                      {calculationResults.hoursPerShift} = {calculationResults.missedShifts}
                    </pre>
                    <p>
                      <strong>Worked hours = </strong>the total sum of regular hours, overtime
                      (&gt;8), overtime (&gt;40), overtime (&gt;48), double time, call back,
                      holiday, and guaranteed hours
                    </p>
                    <pre>{calculationResults.workedHours}</pre>
                  </>
                }>
                <Box
                  sx={{
                    backgroundColor: "#50478b",
                    display: "inline-flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: "5px",
                    verticalAlign: "bottom",
                    marginLeft: "5px",
                    borderRadius: 99,
                    width: 21,
                    height: 21,
                  }}>
                  <InfoOutlinedIcon
                    sx={{
                      color: "white",
                    }}
                  />
                </Box>
              </Tooltip>
            )}
          {row.name != "MissedShiftDeduct" && (
            <DealsheetValueIcon fieldName={row.name} arrayName="nonTaxableDealsheetTypes" />
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
};

NonTaxableExpensesTableRow.propTypes = {
  row: PropTypes.object,
  isFirstWeek: PropTypes.bool,
  isLastWeek: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  handleTotalHoursChange: PropTypes.func,
  handlePaidChange: PropTypes.func,
  onAddAdjustment: PropTypes.func,
  onRemoveAdjustment: PropTypes.func,
  isAdjustment: PropTypes.bool,
  adjustments: PropTypes.array,
  onNonTaxableAdjustmentChange: PropTypes.func,
  isDigitalTimecardEntry: PropTypes.bool,
  handleOnExpenseChange: PropTypes.func,
};

export default NonTaxableExpensesTableRow;
