import { useSelector } from "react-redux";
import { FeatureFlag } from "types/featureFlag";

export default {
  featureIsOn(feature: string, features: FeatureFlag[] | null) {
    if (!features) return false;
    for (const featureFlag of features) {
      if (featureFlag.name === feature) {
        return featureFlag.isActive;
      }
    }
    return false;
  },
};

const isFeatureOn = (state: RootState, featureName: string) => {
  if (!state.featureFlags) return false;
  for (const feature of state.featureFlags) {
    if (feature.name === featureName) {
      return feature.isActive;
    }
  }
  return false;
};

/**
 * @typedef {('isVmsOn' | 'isAdpCodeOn')} BPPFeatureFlags
 */
export function useFeatureFlags() {
  const featureFlags = useSelector((state: RootState) => state.featureFlags);

  const isVmsOn = useSelector((state: RootState) => isFeatureOn(state, "vms"));

  const missedShiftFeeDate: string | Date = useSelector(
    (state: RootState) => state.missedShiftCalculation.missedShiftFeeDate
  );

  const firstTimesheetPeriodStartDate = featureFlags?.find(
    (o) => o.name === "firstTimesheetPeriod"
  )?.configDate;

  const missedShiftDeductVisible: boolean = useSelector(
    (state: RootState) => state.missedShiftCalculation.missedShiftDeductVisible
  );

  const isAdpCodeOn = useSelector((state: RootState) => isFeatureOn(state, "adpCodeOn"));

  return {
    featureFlags,
    isVmsOn,
    missedShiftFeeDate,
    firstTimesheetPeriodStartDate,
    missedShiftDeductVisible,
    isAdpCodeOn,
  };
}
