import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import NumberFormat from "react-number-format";
import Paper from "@mui/material/Paper";
import BillingCommons from "./BillingCommons";
import InvoiceDeliveryMethodLabel from "../timecards/InvoiceDeliveryMethodLabel";
import DealsheetValueIcon from "../timecards/DealsheetValueIcon";

type BillingHoursReadonlyProps = {
  isFirstWeek: boolean;
  rows?: any[];
  vmsName: string;
};

const BillingHoursReadonly = (props: BillingHoursReadonlyProps) => {
  const { rows, isFirstWeek, vmsName } = props;

  if (!rows) return "";

  const table0Rows = rows.filter((x) => x.tableOrder === 0)[0];
  const table1Rows = rows.filter((x) => x.tableOrder === 1);
  const table2Rows = rows.filter((x) => x.tableOrder === 2);
  const table3Rows = rows.filter((x) => x.tableOrder === 3);
  const table4Rows = rows.filter((x) => x.tableOrder === 4);
  const labelProps = {
    value: table0Rows.textFieldValue,
    disabled: false,
    readOnly: true,
    size: "small",
  };
  return (
    <Paper className="timecard-readonly-paper">
      {/* Show Invoice field */}
      <div style={{ textAlign: "right", paddingBottom: "10px" }}>
        <InvoiceDeliveryMethodLabel {...labelProps} />
      </div>
      {/* Show General table */}
      <div>
        <Table sx={{ minWidth: 650 }} className={" timecard-hours-entry-table"} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell sx={{ minWidth: 180 }} align="right">
                Rate
              </TableCell>
              <TableCell sx={{ minWidth: 180 }} align="right">
                Hours
              </TableCell>
              <TableCell sx={{ minWidth: 120 }} align="right">
                Total
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {table1Rows.map((row) => (
              <TableRow
                key={row.name}
                className={
                  (row.name == "FlatIncentiveBonus" || row.name == "IncentiveBonus") &&
                  row.rate != 0
                    ? "timecard-expense-highlight"
                    : ""
                }>
                <TableCell scope="row">{row.title}</TableCell>
                <TableCell align="right">
                  <NumberFormat
                    fixedDecimalScale
                    decimalScale={2}
                    prefix={"$ "}
                    value={row.rate}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                  <DealsheetValueIcon fieldName={row.name} arrayName="billingDealsheetTypes" />
                </TableCell>
                <TableCell align="right">
                  <NumberFormat
                    fixedDecimalScale
                    decimalScale={2}
                    value={row.hours}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </TableCell>
                <TableCell align="right">
                  {row.showFee && (
                    <NumberFormat
                      fixedDecimalScale
                      decimalScale={2}
                      prefix={"$ "}
                      value={row.hours * row.rate}
                      thousandSeparator={true}
                      displayType={"text"}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}

            <TableRow>
              <TableCell align="right" colSpan={3} style={{ height: "50px", fontWeight: "bold" }}>
                Invoice Subtotal
              </TableCell>
              <TableCell align="right">
                <NumberFormat
                  fixedDecimalScale
                  decimalScale={2}
                  prefix={"$ "}
                  value={BillingCommons.getInvoiceSubTotalForBilling(table1Rows, isFirstWeek)}
                  thousandSeparator={true}
                  displayType={"text"}
                  style={{ fontWeight: "bold" }}
                />
              </TableCell>
            </TableRow>
            {isFirstWeek && (
              <TableRow>
                <TableCell>Billable Hours</TableCell>
                <TableCell align="right">
                  <NumberFormat
                    fixedDecimalScale
                    decimalScale={2}
                    prefix={"$ "}
                    value={BillingCommons.getRegularHourRate(table1Rows)}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </TableCell>
                <TableCell align="right">
                  <NumberFormat
                    fixedDecimalScale
                    decimalScale={2}
                    value={BillingCommons.getBillableHoursValue(table1Rows)}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <br />
      {/* Show Discount table */}
      <div>
        <Table sx={{ minWidth: 650 }} className={" timecard-hours-entry-table"} size="small">
          <TableHead>
            <TableRow>
              <TableCell align="right"></TableCell>
              <TableCell sx={{ minWidth: 180 }} align="right"></TableCell>
              <TableCell sx={{ minWidth: 180 }} align="right">
                %
              </TableCell>
              <TableCell sx={{ minWidth: 120 }} align="right">
                Discount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {table2Rows.map((row) => (
              <TableRow key={row.name}>
                <TableCell scope="row">{row.title}</TableCell>
                <TableCell align="right">
                  {row.title === "VMS Fee/Discount" && vmsName && <p>{vmsName}</p>}
                </TableCell>
                <TableCell align="right">
                  <NumberFormat
                    fixedDecimalScale
                    decimalScale={2}
                    value={row.rate}
                    thousandSeparator={true}
                    allowEmptyFormatting={false}
                    displayType={"text"}
                  />
                </TableCell>
                <TableCell align="right">
                  <NumberFormat
                    fixedDecimalScale
                    decimalScale={2}
                    prefix={"$ -"}
                    value={BillingCommons.getDiscountValue(table1Rows, table2Rows, isFirstWeek)}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <br />
      {/* Show Reimbursement table */}
      <div>
        <Table sx={{ minWidth: 650 }} className={" timecard-hours-entry-table"} size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ minWidth: 180 }}></TableCell>
              <TableCell sx={{ minWidth: 120 }} align="right">
                Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {table3Rows.map((row) => (
              <TableRow key={row.name}>
                <TableCell scope="row">{row.title}</TableCell>
                <TableCell align="right">
                  <NumberFormat
                    fixedDecimalScale
                    decimalScale={2}
                    prefix={"$ "}
                    value={row.hours}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <br />
      {/* Show Milage table */}
      <div>
        <Table sx={{ minWidth: 650 }} className={" timecard-hours-entry-table"} size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2} sx={{ minWidth: 180 }} align="right">
                Rate
              </TableCell>
              <TableCell sx={{ minWidth: 180 }} align="right">
                Miles
              </TableCell>
              <TableCell sx={{ minWidth: 120 }} align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {table4Rows.map((row) => (
              <TableRow key={row.name}>
                <TableCell scope="row">{row.title}</TableCell>
                <TableCell align="right">
                  <NumberFormat
                    fixedDecimalScale
                    decimalScale={3}
                    value={row.rate}
                    thousandSeparator={true}
                    displayType={"text"}
                  />
                </TableCell>
                <TableCell align="right">
                  <NumberFormat
                    displayType={"text"}
                    fixedDecimalScale
                    decimalScale={2}
                    value={row.hours}
                    thousandSeparator={true}
                  />
                </TableCell>
                <TableCell align="right">
                  {row.showFee && (
                    <NumberFormat
                      fixedDecimalScale
                      decimalScale={2}
                      prefix={"$ "}
                      value={row.hours * row.rate}
                      thousandSeparator={true}
                      displayType={"text"}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell align="right" colSpan={3} style={{ height: "50px", fontWeight: "bold" }}>
                Invoice Total
              </TableCell>
              <TableCell align="right">
                <NumberFormat
                  fixedDecimalScale
                  decimalScale={2}
                  value={BillingCommons.getInvoiceTotal(
                    table1Rows,
                    table2Rows,
                    table3Rows,
                    table4Rows,
                    isFirstWeek
                  )}
                  thousandSeparator={true}
                  displayType={"text"}
                  style={{ fontWeight: "bold" }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
};

export default BillingHoursReadonly;
