import { TableCell, Typography } from "@mui/material";
import moment from "moment";
import React from "react";

type DtTimeEntryPreviewModalContextTableHeadCellProps = {
  date: string | Date;
};

const DtTimeEntryPreviewModalContextTableHeadCell = ({
  date,
}: DtTimeEntryPreviewModalContextTableHeadCellProps) => {
  const getDayName = (date: string | Date) => {
    if (isNaN(new Date(date).getTime())) {
      return "";
    }
    return moment(date).format("ddd");
  };

  const getShortDate = (date: string | Date) => {
    if (isNaN(new Date(date).getTime())) {
      return "";
    }
    return moment(date).format("MM/DD");
  };

  return (
    <TableCell sx={{ textAlign: "center" }}>
      {getDayName(date)}
      <Typography variant="body2" fontWeight={"500"} textAlign={"center"}>
        {getShortDate(date)}
      </Typography>
    </TableCell>
  );
};

export default DtTimeEntryPreviewModalContextTableHeadCell;
